import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Parser } from 'expr-eval';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { Customers } from 'src/app/models/customer';
import { MaterialCalculation } from 'src/app/models/material-code';
import { MetalMarket, MetalMarketPremiums } from 'src/app/models/metal-market';
import { ProductsV2 } from 'src/app/models/product';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { MetalMarketService } from 'src/app/services/metal-market.service';
import { PricingEquationService } from 'src/app/services/pricing-equation.service';
import { ProductService } from 'src/app/services/product.service';
import { InvoiceItemType } from '../invoicing-shipping/invoicing/models/invoice';
import { BASE_METAL } from '../metal-overhead-equations/metal-overhead-equations.component';
import { CustomerPriceDefault } from '../price-defaults/customer-price-defaults/customer-price-default';
import { ConfirmationService } from '../riva-confirmation/riva-confirmation.service';
import { ProductPricing, ProductPricingDetail } from './product-pricing';

export const TOTAL_PRICING_FORMULA =
  '((metalWeight * metalGramCost) + (metalWeight * castingCost)) + stoneCost + otherCost + ((laborTime*laborTimeCost) + (settingTime*settingTimeCost)) + (((laborTime*laborTimeCost) + (settingTime*settingTimeCost))*overheadPercentage) + (profitPercentage * (((laborTime*laborTimeCost) + (settingTime*settingTimeCost)) + ((laborTime*laborTimeCost) + (settingTime*settingTimeCost))*overheadPercentage))';

@Component({
  selector: 'app-product-pricing',
  templateUrl: './product-pricing.component.html',
  styleUrls: ['./product-pricing.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class ProductPricingComponent implements OnInit, AfterViewInit {
  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private pricingEquationService: PricingEquationService,
    private metalMarketService: MetalMarketService,
    private materialCodeService: MaterialCodeService,
    private analyticsService: AnalyticsService,
    private _confirmationService: ConfirmationService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  equationParser = new Parser();

  displayedColumns = [
    'expander',
    'picture',
    'productName',
    'materials',
    'metalWeight',
    'jewelerTime',
    'setterTime',
    'chainPrice',
    'stonePrice',
    'findingsPrice',
    'estimatedPrice',
    'completeOverridePrice',
    'laborOverridePrice',
    'emptyCell',
  ];

  selectedCustomer = 0;
  customerFilterCtrl = new FormControl();
  customers: Customers[];
  filteredCustomers: Customers[];
  isSaving = false;
  expandedProductPricing: Record<number, boolean> = {};

  formatterDollar = (value: number): string =>
    value ? `$ ${value.toFixed(2)}` : '';
  parserDollar = (value: string): string => value.replace('$ ', '');

  productPricingList = new MatTableDataSource<ProductPricingDetail>([]);
  productLookup: ProductsV2[];
  productLookupNormalized: Record<number, ProductsV2>;
  customerPriceDefaults: CustomerPriceDefault[];
  customerPriceDefault: CustomerPriceDefault;
  metalMarketPremiums: MetalMarketPremiums = {} as MetalMarketPremiums;
  metalMarket: MetalMarket = {} as MetalMarket;
  materialCalculations: MaterialCalculation[] = [];

  ngOnInit(): void {
    forkJoin([
      this.customerService.getList(),
      this.productService.getProductsV2(),
      this.pricingEquationService.getCustomerDefaults(),
      this.metalMarketService.getLatestMetalMarket(),
      this.metalMarketService.getMetalMarketPremiums(),
      this.materialCodeService.getList(),
      this.materialCodeService.getMaterialCodeCalculations(),
    ]).subscribe(
      ([
        customers,
        products,
        customerPriceDefaults,
        metalMarket,
        [premiums],
        { responseObject: materialCodes },
        materialCodeCalculations,
      ]) => {
        this.productLookup = products;
        this.productLookupNormalized = products.reduce(
          (accum, p) => ({ ...accum, [p.productsId]: p }),
          {},
        );
        this.customers = orderBy(customers, 'companyName');
        this.onFilterCustomers();
        this.customerPriceDefaults = customerPriceDefaults;
        this.metalMarket = metalMarket;
        this.metalMarketPremiums = premiums;

        this.materialCalculations = materialCodes.reduce((accum, m) => {
          if (m.multiMetal) return accum;
          const calculation = materialCodeCalculations.find(
            (c) => c.materialCodeID === m.materialCodeId,
          );
          return [
            ...accum,
            {
              ...m,
              ...(calculation ?? {}),
            },
          ];
        }, []);

        this.customerFilterCtrl.valueChanges.subscribe(() => {
          this.onFilterCustomers();
        });
      },
    );
  }

  ngAfterViewInit(): void {
    this.productPricingList.sort = this.sort;
  }

  getMaterialCodeCalculations() {
    forkJoin([
      this.materialCodeService.getList(),
      this.materialCodeService.getMaterialCodeCalculations(),
    ]).subscribe(
      ([{ responseObject: materialCodes }, materialCodeCalculations]) => {
        this.materialCalculations = materialCodes.reduce((accum, m) => {
          if (m.multiMetal) return accum;
          const calculation = materialCodeCalculations.find(
            (c) => c.materialCodeID === m.materialCodeId,
          );
          return [
            ...accum,
            {
              ...m,
              ...(calculation ?? {}),
            },
          ];
        }, []);
      },
    );
  }

  onFilterCustomers() {
    if (
      this.customerFilterCtrl.value == null ||
      this.customerFilterCtrl.value === ''
    ) {
      this.filteredCustomers = orderBy(this.customers, 'companyName');
    } else {
      const customers = this.customers.filter(
        (customer) =>
          customer.customerId
            ?.toLocaleLowerCase()
            .includes(this.customerFilterCtrl.value?.trim()) ||
          customer.companyName
            ?.toLocaleLowerCase()
            .includes(this.customerFilterCtrl.value?.trim()),
      );
      this.filteredCustomers = orderBy(customers, 'companyName');
    }
  }

  onGetProductsByCustomer() {
    this.productPricingList.data = [];
    const products = this.productLookup.filter(
      (p) => p.customerCode === this.selectedCustomer,
    );
    this.customerPriceDefault =
      this.customerPriceDefaults.find(
        (c) => c.customerID === this.selectedCustomer,
      ) ?? this.customerPriceDefaults.find((c) => c.customerID === 0);

    const request = products.map((p) =>
      this.productService.getProductDetailV2(p.productsId),
    );
    forkJoin(request).subscribe((products) => {
      this.productPricingList.data = products.map((product) => {
        const productSubPricing = product.pricing.filter(
          (p) => (p.productsSizesID ?? 0) !== 0,
        );
        const defaultPricing =
          product.pricing.find((p) => (p.productsSizesID ?? 0) === 0) ?? {};

        return {
          ...product,
          ...defaultPricing,
          productsID: product.productsId,
          productName:
            this.productLookupNormalized[product.productsId].productName ?? '',
          productSubPricing,
          productSizeOptions: product.productSizes.filter(
            (s) => s.size !== '-',
          ),
        };
      });
    });
  }

  onAddSubPricing(data: ProductPricingDetail) {
    const subPricing: ProductPricing = {
      key: DateTime.now().toMillis(),
      productsPricingID: 0,
      productsID: data.productsID,
      productsSizesID: 0,
      materialsCodeID: data.materialsCodeID,
      metalWeight: null,
      jewelerTime: null,
      setterTime: null,
      chainPrice: null,
      stonePrice: null,
      findingsPrice: null,
      estimatedPrice: null,
      completeOverridePrice: null,
      laborOverridePrice: null,
      isPricingPerSize: true,
    };
    data.productSubPricing = [...data.productSubPricing, subPricing];
  }

  onChangeProductSize(
    row: ProductPricingDetail,
    subItem: ProductPricingDetail,
  ) {
    subItem.metalWeight = row.rollingAverages[subItem.productsSizesID] ?? 0;
    this.onCalculateEstimatedPricing(subItem);
  }

  onSaveProductPricing() {
    const requests = this.productPricingList.data.reduce(
      (
        accum,
        { materials, picture, pricing, productSizes, productSubPricing, ...p },
      ) => {
        const defaultRequest = this.pricingEquationService.setProductPricing({
          ...p,
          productsSizesID: 0,
        });
        const subRequests = productSubPricing?.map((s) =>
          this.pricingEquationService.setProductPricing(s),
        );
        return [...accum, defaultRequest, ...subRequests];
      },
      [],
    );
    this.isSaving = true;
    forkJoin(requests).subscribe(() => {
      this.onGetProductsByCustomer();
      this.isSaving = false;
    });
  }

  updateExpandedPricing(row: ProductPricingDetail) {
    if (row.isPricingPerSize) return;
    this.expandedProductPricing[row.productsID] =
      !this.expandedProductPricing[row.productsID];
  }

  onDeletePricing(item: ProductPricingDetail, parent: ProductPricingDetail) {
    const currentSizeName = parent.productSizes.find(
      (s) => s.productsSizesID === item.productsSizesID,
    )?.size;

    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Pricing',
        content: `Are you sure you want to delete pricing of size "${currentSizeName}" for ${parent.productName}?`,
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if ((item.key ?? 0) > 0) {
            parent.productSubPricing = parent.productSubPricing.filter(
              (s) => s.key !== item.key,
            );
          } else {
            this.pricingEquationService
              .deleteProductPricing(item.productsPricingID)
              .subscribe(() => {
                parent.productSubPricing = parent.productSubPricing.filter(
                  (s) => s.productsPricingID !== item.productsPricingID,
                );
              });
          }
        }
      });
  }

  onChangeMaterial(row: ProductPricingDetail) {
    if (row.productSizes.length > 0) {
      const productSizesRequest = row.productSizes.map((s) =>
        this.analyticsService.getRollingWeightAverage({
          type: InvoiceItemType.Product,
          itemId: row.productsID,
          sizeId: s.productsSizesID,
          materialCodeId: row.materialsCodeID,
        }),
      );
      forkJoin(productSizesRequest).subscribe((data) => {
        const sizeWithWeight = data.filter((d) => d > 0);
        row.metalWeight =
          sizeWithWeight.length === 0
            ? 0
            : sizeWithWeight.reduce((total, w) => total + w, 0) /
              sizeWithWeight.length;
        row.rollingAverages = row.productSizes.reduce(
          (accum, s, index) => ({
            ...accum,
            [s.productsSizesID]: data[index],
          }),
          {},
        );
        row.productSubPricing = row.productSubPricing.map((s) => ({
          ...s,
          materialsCodeID: row.materialsCodeID,
          metalWeight: row.rollingAverages[s.productsSizesID] ?? 0,
        }));
        this.onCalculateEstimatedPricing(row);
        row.productSubPricing.forEach((s) => {
          this.onCalculateEstimatedPricing(s);
        });
      });
    }
  }

  onCalculateEstimatedPricing(row: ProductPricingDetail) {
    if (!row.materialsCodeID) return 0;
    const materialCalculation = this.materialCalculations.find(
      (c) => c.materialCodeID === row.materialsCodeID,
    );
    if (materialCalculation == null) return 0;

    const metalWeight = row.metalWeight ?? 0;
    const metalGramCost = this.getPricePerGram(materialCalculation) ?? 0;
    const castingCost =
      this.getTotalCastingFee({
        ...materialCalculation,
        totalGrams: metalWeight,
      }) ?? 0;
    const stoneCost = row.stonePrice ?? 0;
    const otherCost = (row.chainPrice ?? 0) + (row.findingsPrice ?? 0);
    const laborTime = row.jewelerTime ?? 0;
    const laborTimeCost = this.customerPriceDefault.stdLaborPerMinCost ?? 0;
    const settingTime = row.setterTime ?? 0;
    const settingTimeCost =
      this.customerPriceDefault.settingLaborPerMinCost ?? 0;
    const overheadPercentage = this.customerPriceDefault.ohPercent ?? 0;
    const profitPercentage = this.customerPriceDefault.profitPercent ?? 0;

    const equation = this.equationParser.parse(TOTAL_PRICING_FORMULA);
    row.estimatedPrice = equation.evaluate({
      metalWeight,
      castingCost,
      metalGramCost,
      laborTime,
      laborTimeCost,
      settingTime,
      settingTimeCost,
      stoneCost,
      otherCost,
      overheadPercentage,
      profitPercentage,
    });
  }

  getTotalCastingFee(data: MaterialCalculation) {
    const list = Array.from({ length: data.totalGrams }, (_, i) => i + 1);
    return (
      list.reduce(
        (total, value) => total + this.getPriceForCurrentGram(data, value),
        0,
      ) / data.totalGrams
    );
  }

  getPriceForCurrentGram(data: MaterialCalculation, currentGram: number) {
    if (currentGram <= data.bottomCutOff) return data.castingFee;
    if (currentGram > data.topCutOff) return 0;
    return (
      data.castingFee *
      (1 -
        (currentGram - data.bottomCutOff) /
          (data.topCutOff - data.bottomCutOff))
    );
  }

  getPricePerGram(data: MaterialCalculation) {
    if (!data.baseMetal1 || data.baseMetal1 === BASE_METAL.notApplicable)
      return 0;
    let metalMarketValue1 = 0;
    let premium1 = 0;

    let metalMarketValue2 = 0;
    let premium2 = 0;

    switch (data.baseMetal1) {
      case BASE_METAL.gold:
        metalMarketValue1 = this.metalMarket.ldpmGold;
        premium1 = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue1 = this.metalMarket.ldpmGold;
        premium1 = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue1 = this.metalMarket.ldpmPlatinum;
        premium1 = this.metalMarketPremiums.platinum;
        break;
      case BASE_METAL.silver:
        metalMarketValue1 = this.metalMarket.ldpmSilver;
        premium1 = this.metalMarketPremiums.silver;
        break;
      case BASE_METAL.palladium:
        metalMarketValue1 = this.metalMarket.ldpmPalladium;
        premium1 = this.metalMarketPremiums.palladium;
        break;
    }

    switch (data.baseMetal2) {
      case BASE_METAL.gold:
        metalMarketValue2 = this.metalMarket.ldpmGold;
        premium2 = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue2 = this.metalMarket.ldpmGold;
        premium2 = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue2 = this.metalMarket.ldpmPlatinum;
        premium2 = this.metalMarketPremiums.platinum;
        break;
      case BASE_METAL.silver:
        metalMarketValue2 = this.metalMarket.ldpmSilver;
        premium2 = this.metalMarketPremiums.silver;
        break;
      case BASE_METAL.palladium:
        metalMarketValue2 = this.metalMarket.ldpmPalladium;
        premium2 = this.metalMarketPremiums.palladium;
        break;
    }
    const preciousPercentage1 = data.preciousPercentage1 ?? 0;
    const preciousPercentage2 = data.preciousPercentage2 ?? 0;

    const alloyCost = data.alloyCost ?? 0;
    const lossFactor = data.lossFactor ?? 0;

    const metal1 = (metalMarketValue1 + premium1) * preciousPercentage1;
    const metal2 = (metalMarketValue2 + premium2) * preciousPercentage2;
    return ((metal1 + metal2) / 31.1035 + alloyCost) * lossFactor;
  }
}
