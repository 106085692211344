import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Parser } from 'expr-eval';
import { forkJoin } from 'rxjs';
import { BASE_METAL } from 'src/app/components/metal-overhead-equations/metal-overhead-equations.component';
import { CustomerPriceDefault } from 'src/app/components/price-defaults/customer-price-defaults/customer-price-default';
import { TOTAL_PRICING_FORMULA } from 'src/app/components/product-pricing/product-pricing.component';
import { MaterialCodeCalculation } from 'src/app/models/material-code';
import { MetalMarket, MetalMarketPremiums } from 'src/app/models/metal-market';
import { ProductDetailV2 } from 'src/app/models/product';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { MetalMarketService } from 'src/app/services/metal-market.service';
import { PricingEquationService } from 'src/app/services/pricing-equation.service';
import { ProductService } from 'src/app/services/product.service';
import { InvoiceDetail } from '../../models/invoice';
import { InvoicingLookupItem } from '../../models/invoicing-lookup-item';

interface ProductPricing {
  productPicPath: string;
  productName: string;
  material: string;
  size: string;
  metalWeight: number;
  jewelerTime: number;
  setterTime: number;
  chainPrice: number;
  stonePrice: number;
  findingsPrice: number;
  estimatedPrice: number;
}

@Component({
  selector: 'invoicing-price-dialog',
  templateUrl: './invoicing-price-dialog.component.html',
  styleUrls: ['./invoicing-price-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvoicingPriceDialogComponent implements OnInit {
  formatterDollar = (value: number): string =>
    value ? `$ ${value.toFixed(2)}` : '';
  parserDollar = (value: string): string => value.replace('$ ', '');

  displayedColumns: string[] = [
    'productPicPath',
    'productName',
    'material',
    'size',
    'metalWeight',
    'jewelerTime',
    'setterTime',
    'chainPrice',
    'stonePrice',
    'findingsPrice',
    'estimatedPrice',
  ];
  invoiceOrders: InvoicingLookupItem[] = [];
  isSelectedAll = true;
  isSomeSelected = false;

  metalMarketPremiums: MetalMarketPremiums = {} as MetalMarketPremiums;
  metalMarket: MetalMarket = {} as MetalMarket;
  materialCalculations: MaterialCodeCalculation[] = [];
  customerPriceDefaults: CustomerPriceDefault[];
  customerPriceDefault: CustomerPriceDefault;
  productDetail: ProductDetailV2;
  equationParser = new Parser();
  productPricing: ProductPricing[] = [];

  constructor(
    public dialogRef: MatDialogRef<InvoicingPriceDialogComponent>,
    private productService: ProductService,
    private pricingEquationService: PricingEquationService,
    private metalMarketService: MetalMarketService,
    private materialCodeService: MaterialCodeService,

    @Inject(MAT_DIALOG_DATA)
    public props: {
      item: InvoiceDetail;
      selectedMetalMarket: MetalMarket;
    },
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.productService.getProductDetailV2(this.props.item.productsId),
      this.pricingEquationService.getCustomerDefaults(),
      this.metalMarketService.getMetalMarketPremiums(),
      this.materialCodeService.getMaterialCodeCalculations(),
    ]).subscribe(
      ([
        product,
        customerPriceDefaults,
        [premiums],
        materialCodeCalculations,
      ]) => {
        this.productDetail = product;
        this.customerPriceDefaults = customerPriceDefaults;
        this.customerPriceDefault =
          this.customerPriceDefaults.find(
            (c) => c.customerID === this.props.item.customerId,
          ) ?? this.customerPriceDefaults.find((c) => c.customerID === 0);

        this.metalMarketPremiums = premiums;
        this.materialCalculations = materialCodeCalculations;

        const currentProductPricing =
          this.productDetail.pricing.find(
            (p) => p.productsSizesID === this.props.item.productsSizeId,
          ) ??
          this.productDetail.pricing.find((p) => (p.productsSizesID ?? 0) === 0);
        
        const productPricing = {
          productPicPath: this.props.item.productPicPath,
          productName: this.props.item.productName,
          material: this.props.item.material,
          size: this.props.item.size,
          metalWeight: this.props.item.weight ?? 0,
          jewelerTime: currentProductPricing.jewelerTime ?? 0,
          setterTime: currentProductPricing.setterTime ?? 0,
          chainPrice: currentProductPricing.chainPrice ?? 0,
          stonePrice: currentProductPricing.stonePrice ?? 0,
          findingsPrice: currentProductPricing.findingsPrice ?? 0,
          estimatedPrice: 0
        }

        this.productPricing = [{
          ...productPricing,
          estimatedPrice: this.onCalculateEstimatedPricing(productPricing),
        }]
      },
    );
  }
  onCalculatePricing(row: ProductPricing) {
    row.estimatedPrice = this.onCalculateEstimatedPricing(row);
  }

  onCalculateEstimatedPricing(row: ProductPricing) {
    if (!this.props.item.materialCodeId) return 0;
    const materialCalculation = this.materialCalculations.find(
      (c) => c.materialCodeID === this.props.item.materialCodeId,
    );
    if (materialCalculation == null) return 0;

    const laborTime = row.jewelerTime ?? 0;
    const settingTime = row.setterTime ?? 0;
    const metalWeight = row.metalWeight ?? 0;
    const metalGramCost = this.getPricePerGram(materialCalculation) ?? 0;
    const castingCost =
      this.getTotalCastingFee({
        ...materialCalculation,
        totalGrams: metalWeight,
      }) ?? 0;
    const stoneCost = row.stonePrice ?? 0;
    const otherCost =
      (row.chainPrice ?? 0) +
      (row.findingsPrice ?? 0);
    const laborTimeCost = this.customerPriceDefault.stdLaborPerMinCost ?? 0;
    const settingTimeCost =
      this.customerPriceDefault.settingLaborPerMinCost ?? 0;
    const overheadPercentage = this.customerPriceDefault.ohPercent ?? 0;
    const profitPercentage = this.customerPriceDefault.profitPercent ?? 0;

    const equation = this.equationParser.parse(TOTAL_PRICING_FORMULA);
    const total = equation.evaluate({
      metalWeight,
      castingCost,
      metalGramCost,
      laborTime,
      laborTimeCost,
      settingTime,
      settingTimeCost,
      stoneCost,
      otherCost,
      overheadPercentage,
      profitPercentage,
    });
    return Number.isNaN(total) ? 0 : Number.parseFloat(total.toFixed(2));
  }

  getTotalCastingFee(data: MaterialCodeCalculation) {
    const list = Array.from({ length: data.totalGrams }, (_, i) => i + 1);
    return (
      list.reduce(
        (total, value) => total + this.getPriceForCurrentGram(data, value),
        0,
      ) / data.totalGrams
    );
  }

  getPriceForCurrentGram(data: MaterialCodeCalculation, currentGram: number) {
    if (currentGram <= data.bottomCutOff) return data.castingFee;
    if (currentGram > data.topCutOff) return 0;
    return (
      data.castingFee *
      (1 -
        (currentGram - data.bottomCutOff) /
          (data.topCutOff - data.bottomCutOff))
    );
  }

  getPricePerGram(data: MaterialCodeCalculation) {
    if (data == null || !data.baseMetal1 || data.baseMetal1 === BASE_METAL.notApplicable)
      return 0;
    let metalMarketValue1 = 0;
    let premium1 = 0;

    let metalMarketValue2 = 0;
    let premium2 = 0;

    switch (data.baseMetal1) {
      case BASE_METAL.gold:
        metalMarketValue1 = this.props.selectedMetalMarket.ldpmGold;
        premium1 = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue1 = this.props.selectedMetalMarket.ldpmGold;
        premium1 = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue1 = this.props.selectedMetalMarket.ldpmPlatinum;
        premium1 = this.metalMarketPremiums.platinum;
        break;
      case BASE_METAL.silver:
        metalMarketValue1 = this.props.selectedMetalMarket.ldpmSilver;
        premium1 = this.metalMarketPremiums.silver;
        break;
      case BASE_METAL.palladium:
        metalMarketValue1 = this.props.selectedMetalMarket.ldpmPalladium;
        premium1 = this.metalMarketPremiums.palladium;
        break;
    }

    switch (data.baseMetal2) {
      case BASE_METAL.gold:
        metalMarketValue2 = this.props.selectedMetalMarket.ldpmGold;
        premium2 = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue2 = this.props.selectedMetalMarket.ldpmGold;
        premium2 = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue2 = this.props.selectedMetalMarket.ldpmPlatinum;
        premium2 = this.metalMarketPremiums.platinum;
        break;
      case BASE_METAL.silver:
        metalMarketValue2 = this.props.selectedMetalMarket.ldpmSilver;
        premium2 = this.metalMarketPremiums.silver;
        break;
      case BASE_METAL.palladium:
        metalMarketValue2 = this.props.selectedMetalMarket.ldpmPalladium;
        premium2 = this.metalMarketPremiums.palladium;
        break;
    }
    const preciousPercentage1 = data.preciousPercentage1 ?? 0;
    const preciousPercentage2 = data.preciousPercentage2 ?? 0;

    const alloyCost = data.alloyCost ?? 0;
    const lossFactor = data.lossFactor ?? 0;

    const metal1 = (metalMarketValue1 + premium1) * preciousPercentage1;
    const metal2 = (metalMarketValue2 + premium2) * preciousPercentage2;
    return ((metal1 + metal2) / 31.1035 + alloyCost) * lossFactor;
  }

  onReplacePrice() {
    const [pricing] = this.productPricing ?? []
    this.dialogRef.close(pricing.estimatedPrice)
  }
}
