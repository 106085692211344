import { Component } from '@angular/core';

@Component({
  selector: 'app-price-defaults',
  templateUrl: './price-defaults.component.html',
  styleUrls: ['./price-defaults.component.scss'],
})
export class PriceDefaultsComponent {
  
}
