import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';

import { AlertService } from 'src/app/custom/_alert';
import { GenericComponent } from '../generic/generic.component';

import { MatDialog } from '@angular/material/dialog';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { ORDER_DETAILS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/order-details-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { CustomerAddress, Customers } from 'src/app/models/customer';
import { MaterialCode } from 'src/app/models/material-code';
import {
  OrderStatus,
  Orders,
  OrdersDetails,
} from 'src/app/models/orders.model';
import { Product, ProductsInfo } from 'src/app/models/product';
import { CustomerService } from 'src/app/services/customer.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { OrderService } from 'src/app/services/order.service';
import { OrderdetailsService } from 'src/app/services/orderdetails.service';
import { ProductService } from 'src/app/services/product.service';
import { OrderUploadDialogComponent } from '../orderdetails/order-upload-dialog/order-upload-dialog.component';
import { OrdersConfirmationComponent } from '../orders-dialog/orders-confirmation.component';
import { OrdersProgressComponent } from '../orders-dialog/orders-progress.component';
import { ConfirmationService } from '../riva-confirmation/riva-confirmation.service';
import { SharedService } from './../../services/shared.service';
import { WorkOrderService } from './../../services/work-order.service';
import { RelatedInvoicesDialogComponent } from './related-invoices-dialog/related-invoices-dialog.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent extends GenericComponent implements OnInit {
  editMode: boolean = false;
  hideOrderDetails: boolean = false;
  hidebtns: boolean = true;
  customersList: Customers[];
  selectedCustomer = new Customers();
  selectedCustomerId: number = 1;
  productList: Product[] = [];
  productPerCustomer: Product[];
  productPerCustomer2: Product[];
  materialCodes: MaterialCode[];
  orderList: Orders[];
  ordersAllList: Orders[];
  selectedOrder = new Orders();
  selectedOrderRaw = new Orders();
  orderDetailList: OrdersDetails[];
  selectedOrderId: number;
  orderStatusList: OrderStatus[];
  cancelledOrderIdx: number = 0;
  productsInfo: ProductsInfo[];
  allProductsInfo: ProductsInfo[];
  selectedOrdersDetails: OrdersDetails;
  dialogProductId: number;
  dialogProductsInfoId: number;
  disableFlag: boolean = true;
  customerControl = new FormControl();
  filteredCustomers: Observable<Customers[]>;
  billingAddresses: CustomerAddress[] = [];
  shippingAddresses: CustomerAddress[] = [];
  orderDetailsFeatureKey = ORDER_DETAILS_FEATURE_KEY;
  ordersType = 0;
  hasItems = false;
  hasUnInvoiced = false;
  isLoadingRequiredData = false;
  isLoadingList = true;
  isInternalFromInitialLoad = false;
  isRepairFromInitialLoad = false;
  hasUploadOrderAccess = false;
  isExportingFile = false;

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    public sharedService: SharedService,
    public dialog: MatDialog,
    private workOrderService: WorkOrderService,
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private productService: ProductService,
    private orderService: OrderService,
    private orderDetailService: OrderdetailsService,
    private materialCodeService: MaterialCodeService,
    private userPermissionService: UserPermissionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _confirmationService: ConfirmationService,
  ) {
    super(loadService, alertService);
    this.userPermissionService.checkPagePermission(PAGE_NAME.orderDetails);
  }

  ngOnInit(): void {
    this.loadwithParameter();
  }

  loadwithParameter() {
    this.route.params.subscribe((params: Params) => {
      if (params['ordersId'] !== undefined) {
        const ordersId = +params['ordersId'];
        if (ordersId === -1) {
          this.ordersType = 1;
        } else if (ordersId === -2) {
          this.ordersType = 3;
        } else {
          this.ordersType = 0;
        }

        this.selectedOrderId = ordersId > 0 ? ordersId : 0;

        this.isInternalFromInitialLoad = ordersId === -1;
        this.isRepairFromInitialLoad = ordersId === -2;
        this.load(this.selectedOrderId);
      }
    });
  }

  get isFormValid() {
    return (
      this.selectedCustomer.custIdno &&
      (this.isInternalFromInitialLoad ||
        this.isRepairFromInitialLoad ||
        this.selectedOrder.pointernal) &&
      this.selectedOrder.requiredDate &&
      this.selectedOrder.receivedDate
    );
  }

  hasPermission(key) {
    return !this.selectedOrderId || this.userPermissionService.hasAccess(key);
  }

  formatAddress(address: CustomerAddress) {
    const address1 = address.address1 ? `${address.address1} ` : '';
    const address2 = address.address2 ? `${address.address2} ` : '';
    const city = address.city ? `${address.city}, ` : '';
    const region = address.region ? `${address.region} ` : '';
    const postalCode = address.postalCode ? `${address.postalCode}, ` : '';
    const country = address.country ? `${address.country}` : '';

    return `${address1}${address2}${city}${region}${postalCode}${country}`;
  }

  load(orderId) {
    this.isLoadingRequiredData = true;
    forkJoin([
      this.customerService.getList(),
      this.productService.getList(),
      this.materialCodeService.getList(),
      this.orderService.getOrderById(this.selectedOrderId),
    ])
      .pipe(
        map(([customerlist, productList, materialCodes, selectedOrder]) => {
          if (orderId == 0) {
            this.editMode = true;
          } else {
            this.selectedOrder = { ...(selectedOrder ?? new Orders()) };
            this.selectedOrderRaw = { ...(selectedOrder ?? new Orders()) };
          }

          this.customersList = orderBy(
            customerlist.map((c) => ({
              ...c,
              addresses: c.addresses?.map((a) => ({
                ...a,
                fullAddress: this.formatAddress(a),
              })),
            })),
            'companyName',
          );
          this.initializeFilter();
          if (this.isInternalFromInitialLoad || this.isRepairFromInitialLoad) {
            this.selectedOrder.receivedDate = DateTime.now().toJSDate();
            this.selectedOrder.requiredDate = DateTime.now()
              .plus({ weeks: 2 })
              .toJSDate();
            this.selectedOrder.customerId =
              this.customersList.find((c) => c.customerId === 'RIVA')
                ?.custIdno ?? 0;
          }
          this.selectedCustomer =
            this.customersList.filter(
              (x) => x.custIdno == this.selectedOrder.customerId,
            )[0] ?? ({} as Customers);
          const allowedUploadOrderCustomerIds = ['SIG'];
          this.hasUploadOrderAccess = allowedUploadOrderCustomerIds.includes(
            this.selectedCustomer.customerId,
          );

          this.shippingAddresses =
            this.selectedCustomer?.addresses.filter((a) => !a.addressType) ??
            [];
          this.billingAddresses =
            this.selectedCustomer?.addresses.filter((a) => a.addressType) ?? [];

          this.productList = productList;

          this.materialCodes = materialCodes.responseObject;

          this.getOrderDetailList();
          this.isLoadingRequiredData = false;

          return {
            customerlist: this.customersList,
            productList,
            materialCodes,
          };
        }),
      )
      .subscribe(
        (response) => {
          this.refreshOrderDetailList();

          this.loadService.loadContent(false);
        },
        (error) => {
          this.alertService.error(error.statusText);
          this.loadService.loadContent(false);
        },
      );
  }

  onLoading(isLoading) {
    this.isLoadingList = isLoading;
  }

  initializeFilter() {
    this.filteredCustomers = this.customerControl.valueChanges.pipe(
      startWith(this.selectedCustomer.companyName),
      map((value) => this._filterCustomers(value)),
    );
  }

  onCustomerChange(customer: Customers) {
    this.selectedOrder.customerId = customer.custIdno;
    this.shippingAddresses =
      customer.addresses.filter((a) => !a.addressType) ?? [];
    this.billingAddresses =
      customer.addresses.filter((a) => a.addressType) ?? [];
  }

  private _filterCustomers(name: string | Object): Customers[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      const customers = this.customersList.filter((option) =>
        option.companyName.toLowerCase().includes(filterValue),
      );
      return orderBy(customers, 'companyName');
    } else if (typeof name === 'object') {
      const filterValue: string = name['companyName'];
      const customers = this.customersList.filter((option) =>
        option.companyName.toLowerCase().includes(filterValue.toLowerCase()),
      );
      return orderBy(customers, 'companyName');
    }
    return this.customersList;
  }

  getOrderDetailList() {
    if (this.selectedOrder.ordersDetails.length > 0) {
      this.orderDetailList = this.selectedOrder.ordersDetails;
    }
  }

  editOrder() {
    this.cancelledOrderIdx = this.selectedOrder.ordersId;
    this.editMode = !this.editMode;
  }

  cancelChange() {
    this.selectedOrder = { ...this.selectedOrderRaw };
    this.editMode = !this.editMode;
  }

  saveOrder() {
    const requiredDate = this.selectedOrder.requiredDate
      ? DateTime.fromJSDate(new Date(this.selectedOrder.requiredDate)).toFormat(
          'yyyy-MM-dd',
        )
      : null;
    const receivedDate = this.selectedOrder.receivedDate
      ? DateTime.fromJSDate(new Date(this.selectedOrder.receivedDate)).toFormat(
          'yyyy-MM-dd',
        )
      : null;
    const entryDate = this.selectedOrder.entryDate
      ? DateTime.fromJSDate(new Date(this.selectedOrder.entryDate)).toFormat(
          'MM/dd/yyyy',
        )
      : null;
    const totalShippedDate = this.selectedOrder.totalShippedDate
      ? DateTime.fromJSDate(
          new Date(this.selectedOrder.totalShippedDate),
        ).toFormat('MM/dd/yyyy')
      : null;

    const data = {
      ...this.selectedOrder,
      requiredDate,
      receivedDate,
      entryDate,
      totalShippedDate,
      ordersType: this.selectedOrder.ordersType ?? this.ordersType,
    };
    this.orderService.addupdate(data).subscribe(
      (res) => {
        this.selectedOrder = res;

        this.alertService.success('Order successfully UPDATED.');

        this.gotoOrders(this.selectedOrder.ordersId);

        this.selectedCustomer = this.customersList.filter(
          (x) => x.custIdno == this.selectedOrder.customerId,
        )[0];
        this.refreshOrderDetailList();
      },
      (err) => {
        this.alertService.success('Order failed to UPDATED.');
        err;
      },
    );

    this.editMode = !this.editMode;
  }
  closeResult = '';

  orderDetails: OrdersDetails[] = [];
  notification = null;
  orderDetailsForm: FormArray = this.fb.array([]);

  modalOpen(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeMatSizeOpen(content) {
    this.modalOpen(content);
  }

  refreshOrderDetailList() {
    this.orderDetailList = [];
    this.orderDetailsForm = this.fb.array([]);

    // Added refresh product per customer
    this.productPerCustomer = this.productList.filter(
      (x) =>
        x.customerCode == this.selectedCustomer.custIdno || x.customerCode == 5,
    );

    this.productPerCustomer2 = this.productList.filter(
      (x) =>
        x.customerCode == this.selectedCustomer.custIdno || x.customerCode == 5,
    );

    if (this.selectedOrder.ordersId == 0) {
      return;
    }

    this.orderService
      .getOrderById(this.selectedOrder.ordersId)
      .subscribe((res) => {
        this.selectedOrder = res;

        if (this.selectedOrder.ordersDetails.length > 0) {
          this.orderDetailList = this.selectedOrder.ordersDetails;

          (this.orderDetailList as []).forEach((detail: OrdersDetails) => {
            this.orderDetailsForm.push(
              this.fb.group({
                ordersDetailsId: [detail.ordersDetailsId],
                ordersId: [detail.ordersId],
                productsId: [detail.productsId],
                productsInfoId: [detail.productsInfoId],
                qtyordered: [detail.qtyordered],
                qtyshipped: [detail.qtyshipped],
                qtyinvoiced: [detail.qtyinvoiced],
                entryDate: [detail.entryDate],
                dueDate: [detail.dueDate],
                custAdrsId: [detail.custAdrsId],
                comment: [detail.comment],
                products: [detail.products],
              }),
            );
          });
        }
      });
  }

  newDetails() {
    if (this.selectedOrder.ordersId == 0) {
      return;
    }

    let orderDetail = new OrdersDetails();

    orderDetail.ordersDetailsId = 0;
    orderDetail.ordersId = this.selectedOrder.ordersId;
    // orderDetail.custAdrsId =
    //   this.selectedCustomer.customersAddresses[0].customersAddressesId;
    orderDetail.products = this.productList.find(
      (x) => x.customerCode == this.selectedOrder.customerId,
    );
    orderDetail.entryDate = new Date();
    orderDetail.dueDate = this.selectedOrder.requiredDate;
    orderDetail.qtyshipped = 0;
    orderDetail.qtyinvoiced = 0;

    this.orderDetailsForm.push(
      this.fb.group({
        ordersDetailsId: [orderDetail.ordersDetailsId],
        ordersId: [orderDetail.ordersId],
        productsId: [orderDetail.productsId],
        productsInfoId: [orderDetail.productsInfoId],
        qtyordered: [orderDetail.qtyordered],
        qtyshipped: [orderDetail.qtyshipped],
        qtyinvoiced: [orderDetail.qtyinvoiced],
        entryDate: [orderDetail.entryDate],
        dueDate: [orderDetail.dueDate],
        custAdrsId: [orderDetail.custAdrsId],
        comment: [orderDetail.comment],
      }),
    );
  }

  get EnableUpdateOrderDetails() {
    return this.orderDetailsForm.value.length == 0;
  }

  updateDetails(fg: AbstractControl, i) {
    this.productsInfo = this.allProductsInfo;

    let orderDetail: OrdersDetails;

    orderDetail = fg.value;

    var checker = this.checkProductsInfo(orderDetail);
    if (checker == false) return;
    this.orderDetailService.addupdate(fg.value).subscribe((res) => {
      if (fg.value.productsRoutingId == 0) this.showNotification('insert');
      else this.showNotification('update');
      fg.patchValue({ ordersDetailsId: res.ordersDetailsId });

      this.refreshOrderDetailList();
    });
  }

  showNotification(category) {
    switch (category) {
      case 'insert':
        this.notification = { class: 'text-success', message: 'saved!' };
        break;
      case 'update':
        this.notification = { class: 'text-primary', message: 'updated!' };
        break;
      case 'delete':
        this.notification = { class: 'text-danger', message: 'deleted!' };
        break;

      default:
        break;
    }
    setTimeout(() => {
      this.notification = null;
    }, 3000);
  }

  deleteDetail(orderDetailsId, i) {
    if (orderDetailsId == 0) this.orderDetailsForm.removeAt(i);
    else if (confirm('Are you sure to delete this record ?'))
      this.orderDetailService.delete(orderDetailsId).subscribe(() => {
        this.orderDetailsForm.removeAt(i);
        this.showNotification('delete');
      });
  }

  getProductInfoById(productId: number, fg: AbstractControl) {
    this.productsInfo = [];

    if (productId == undefined) {
      return;
    }

    let selectedProduct = this.productPerCustomer.filter(
      (x) => x.productsId == productId,
    )[0];

    if (selectedProduct != undefined) {
      if (
        selectedProduct.productsInfo != undefined &&
        selectedProduct.productsInfo.length > 0
      ) {
        this.productsInfo = selectedProduct.productsInfo;
      }
    }
  }

  getProductInfoByProduct(productId: number) {
    this.productsInfo = [];

    if (productId == undefined) {
      return;
    }

    let selectedProduct = this.productPerCustomer.filter(
      (x) => x.productsId == productId,
    )[0];

    if (selectedProduct != undefined) {
      this.dialogProductId = productId;
      if (
        selectedProduct.productsInfo != undefined &&
        selectedProduct.productsInfo.length > 0
      ) {
        this.productsInfo = selectedProduct.productsInfo;
        this.dialogProductsInfoId =
          selectedProduct.productsInfo[0].productsInfoId;
      } else {
        this.dialogProductsInfoId = 0;
      }
    }
  }

  getProductInfoDescByID(productsInfoId) {
    let materialsize = '';
    let productInfo = this.allProductsInfo.filter(
      (x) => x.productsInfoId == productsInfoId,
    )[0];
    if (productInfo != undefined) {
      materialsize = this.getProductInfoDesc(
        productInfo.materialCodeId,
        productInfo.size,
      );
    }

    return materialsize;
  }

  getProductInfoDesc(materialCodeId: number, size: string) {
    let returnStr = '';

    if (materialCodeId == undefined) {
      return returnStr;
    }

    let materialCode = this.materialCodes.filter(
      (x) => x.materialCodeId == materialCodeId,
    )[0];

    returnStr = materialCode.code + '  |  SZ-' + size;

    return returnStr;
  }

  onUpdateOrderDetails(fg: AbstractControl, i) {
    this.updateDetails(fg, i);
  }

  checkProductsInfo(orderDetail: OrdersDetails) {
    let checker = false;
    let endMessage = ' Please select Product first then Material and Sizes';
    let product = this.productPerCustomer.filter(
      (x) => x.productsId == orderDetail.productsId,
    )[0];

    if (orderDetail.ordersDetailsId == 0) {
      alert('Please Input Product using the BLUE BUTTON');
      return false;
    }

    if (product.productsInfo.length > 0) {
      var check = product.productsInfo.filter(
        (x) => x.productsInfoId == orderDetail.productsInfoId,
      )[0];

      if (check == undefined || check == null) {
        alert('Product and Material NOT match! ' + endMessage);
        return checker;
      }
    } else {
      alert('Product has NO Material and Sizes');
      return checker;
    }

    checker = true;
    return checker;
  }

  gotoOrdersView() {
    this.router.navigate(['/main/ordersview']);
  }

  gotoOrders(ordersId) {
    window.location.href = '/main/orders/' + ordersId;
  }

  editProductDetails(modalName, orderDetail) {
    this.selectedOrdersDetails = orderDetail;
    this.dialogProductId = orderDetail.productsId;
    this.dialogProductsInfoId = orderDetail.productsInfoId;

    this.modalOpen(modalName);
  }

  saveDetailsFromDiaog() {
    this.selectedOrdersDetails.productsId = this.dialogProductId;
    this.selectedOrdersDetails.productsInfoId = this.dialogProductsInfoId;
    this.selectedOrdersDetails.qtyordered =
      this.selectedOrdersDetails.qtyordered == null
        ? 0
        : this.selectedOrdersDetails.qtyordered;

    this.orderDetailService
      .addupdate(this.selectedOrdersDetails)
      .subscribe((res) => {
        this.selectedOrdersDetails = res;

        if (this.selectedOrdersDetails.productsId == 0)
          this.showNotification('insert');
        else this.showNotification('update');

        this.refreshOrderDetailList();
      });
  }

  setSelectedDialogProductsInfo(value) {
    this.dialogProductsInfoId = value;
  }

  getProductName(id) {
    let productname = '';
    let product = this.productList.filter((x) => x.productsId == id)[0];
    if (product != undefined) {
      productname = product.productName;
    }

    return productname;
  }

  generateWorkOrders(): void {
    const dialogRef = this.dialog.open(OrdersConfirmationComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dialogProgressRef = this.dialog.open(OrdersProgressComponent, {
          disableClose: true,
          width: '400px',
        });

        this.workOrderService
          .generateWorkOrders(this.selectedOrderId)
          .subscribe(
            (response) => {
              this.loadService.reloadOrderDetails();
              dialogProgressRef.close();
            },
            () => {
              dialogProgressRef.close();
            },
          );
      }
    });
  }
  onListLoad({ hasItems, hasUnInvoiced }) {
    this.hasItems = hasItems;
    this.hasUnInvoiced = hasUnInvoiced;
  }

  onByPassWorkOrder(bypassWorkOrder = false) {
    const content = bypassWorkOrder
      ? 'Are you sure you want to invoice all directly and skip work order?'
      : 'Are you sure you want to pre-invoice the orders?';
    this._confirmationService
      .showConfirmation({
        title: 'Jump to invoicing?',
        content,
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        sessionStorage.setItem(
          'orderDetailByPassWorkOrderOrdersId',
          this.selectedOrder.ordersId.toString(),
        );
        sessionStorage.setItem(
          'bypassWorkOrder',
          bypassWorkOrder ? 'yes' : 'no',
        );
        this.router.navigate(['invoicing-and-shipping/invoicing/0']);
      });
  }

  displayFn(item: Customers): string {
    return item && item.companyName ? item.companyName : '';
  }

  onOpenRelatedInvoices() {
    this.dialog.open(RelatedInvoicesDialogComponent, {
      disableClose: true,
      minWidth: '800px',
      autoFocus: false,
      data: {
        ordersId: this.selectedOrderId,
      },
    });
  }
  onByPassAllWorkOrder() {
    this.sharedService.byPassWorkOrder();
  }
  onOpenUploadDialog() {
    const ref = this.dialog.open(OrderUploadDialogComponent, {
      disableClose: true,
      minWidth: '1500px',
      autoFocus: false,
      data: {
        customerId: this.selectedOrder.customerId,
        ordersId: this.selectedOrderId,
      },
    });
    ref.afterClosed().subscribe((reload) => {
      if (reload) {
        this.loadService.reloadOrderDetails();
      }
    });
  }

  onDeleteOrder() {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Order?',
        content: 'Are you sure you want to delete this order?',
        confirmLabel: 'Yes, delete it.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.orderService.deleteOrder(this.selectedOrderId).subscribe(() => {
          this.gotoOrdersView();
        });
      });
  }

  onDownloadExcelFile() {
    this.isExportingFile = true;
    this.orderDetailService
      .exportOrderToExcel(this.selectedOrderId)
      .subscribe((response) => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `order_details_${this.selectedOrder?.pointernal ?? ''}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.isExportingFile = false;
      });
  }
}
