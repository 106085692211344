import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { forkJoin } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { PricingEquationService } from 'src/app/services/pricing-equation.service';
import { CustomerPriceDefault } from './customer-price-default';

@Component({
  selector: 'app-customer-price-defaults',
  templateUrl: './customer-price-defaults.component.html',
  styleUrls: ['./customer-price-defaults.component.scss'],
})
export class CustomerPriceDefaultsComponent implements OnInit, AfterViewInit {
  constructor(
    private customerService: CustomerService,
    private pricingEquationService: PricingEquationService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'companyName',
    'stdLaborPerMinCost',
    'settingLaborPerMinCost',
    'ohPercent',
    'profitPercent',
    'emptyCell',
  ];

  customers = new MatTableDataSource<CustomerPriceDefault>([]);
  customerOptions: Partial<CustomerPriceDefault>[] = [];
  defaultForCustomer: CustomerPriceDefault;
  isSaving = false;

  ngOnInit(): void {
    this.loadDefaults();
  }

  loadDefaults() {
    forkJoin([
      this.customerService.getList(),
      this.pricingEquationService.getCustomerDefaults(),
    ]).subscribe(([customers, customerDefaults]) => {
      this.customerOptions = orderBy(customers, 'companyName').reduce((accum, c) => {
        const isExist = customerDefaults.some(
          (d) => d.customerID === c.custIdno,
        );
        if (isExist) return accum;
        return [
          ...accum,
          {
            customerID: c.custIdno,
            companyName: c.companyName,
          },
        ];
      }, []);
      this.defaultForCustomer = customerDefaults.find(
        (d) => d.customerID === 0,
      );
      this.customers.data = orderBy(customerDefaults, [
        'prC_DefaultsID',
      ]).reduce((accum, customerDefault, index) => {
        if (customerDefault.customerID === 0) {
          return [
            ...accum,
            {
              ...customerDefault,
              companyName: 'All customers',
              sortOrder: index + 1,
            },
          ];
        } else {
          const customer =
            customers.find((c) => c.custIdno === customerDefault.customerID) ??
            {};
          return [
            ...accum,
            {
              ...customerDefault,
              companyName: customer.companyName ?? '',
              sortOrder: index + 1,
            },
          ];
        }
      }, []);
    });
  }

  ngAfterViewInit(): void {
    this.customers.sort = this.sort;
  }

  onCreateNewDefault() {
    this.customers.data = [
      ...this.customers.data,
      {
        isNew: true,
        customerID: 0,
        companyName: '',
        prC_DefaultsID: 0,
        sortOrder: this.customers.data.length + 1,
      },
    ];
  }

  onSaveDefaults() {
    const requests = this.customers.data.reduce((accum, customer) => {
      if (customer.isNew && !customer.customerID) return accum;
      const hasValue =
        (customer.stdLaborPerMinCost || 0) !== 0 ||
        (customer.settingLaborPerMinCost || 0) !== 0 ||
        (customer.ohPercent || 0) !== 0 ||
        (customer.profitPercent || 0) !== 0;
      if (customer.prC_DefaultsID > 0 || hasValue) {
        if (!hasValue) {
          return [
            ...accum,
            this.pricingEquationService.deleteCustomerDefaults(
              customer.prC_DefaultsID,
            ),
          ];
        } else {
          return [
            ...accum,
            this.pricingEquationService.setCustomerDefaults({
              ...customer,
              stdLaborPerMinCost: customer.stdLaborPerMinCost || null,
              settingLaborPerMinCost: customer.settingLaborPerMinCost || null,
              ohPercent: customer.ohPercent || null,
              profitPercent: customer.profitPercent || null,
            }),
          ];
        }
      }
      return accum;
    }, []);
    this.isSaving = true;
    forkJoin(requests).subscribe(() => {
      this.loadDefaults();
      this.isSaving = false;
    });
  }
}
