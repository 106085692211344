import { Component } from '@angular/core';
import { Parser } from 'expr-eval';
import { TOTAL_PRICING_FORMULA } from '../product-pricing/product-pricing.component';

@Component({
  selector: 'app-equations',
  templateUrl: './equations.component.html',
  styleUrls: ['./equations.component.scss'],
})
export class EquationsComponent {
  equation = {
    metalWeight: 0,
    castingCost: 0,
    metalGramCost: 0,
    laborTime: 0,
    laborTimeCost: 0,
    settingTime: 0,
    settingTimeCost: 0,
    stoneCost: 0,
    otherCost: 0,
    overheadPercentage: 0,
    profitPercentage: 0,
  };

  total = 0;
  totalMetalCost = 0;
  totalOtherCost = 0;
  totalLaborCost = 0;
  totalOverheadCost = 0;
  totalProfit = 0;
  equationParser = new Parser();
  totalFormula = TOTAL_PRICING_FORMULA;
  totalMetalCostFormula = '(metalWeight * metalGramCost) + (metalWeight * castingCost)';
  totalOtherCostFormula = 'stoneCost + otherCost';
  totalLaborCostFormula = '(laborTime*laborTimeCost) + (settingTime*settingTimeCost)';
  totalOverheadCostFormula = '((laborTime*laborTimeCost) + (settingTime*settingTimeCost)) * overheadPercentage';
  totalProfitFormula = 'profitPercentage * (((laborTime*laborTimeCost) + (settingTime*settingTimeCost)) + (((laborTime*laborTimeCost) + (settingTime*settingTimeCost))*overheadPercentage))';


  onValueChange() {
    this.calculateLaborCost();
    this.calculateOtherCost();
    this.calculateTotalMetalCost();
    this.calculateOverheadCost();
    this.calculateProfit();
    this.calculateTotal();
  }

  calculateTotalMetalCost() {
    const equation = this.equationParser.parse(this.totalMetalCostFormula);
    this.totalMetalCost = equation.evaluate(this.equation);
  }

  calculateOtherCost() {
    const equation = this.equationParser.parse(this.totalOtherCostFormula);
    this.totalOtherCost = equation.evaluate(this.equation);
  }

  calculateLaborCost() {
    const equation = this.equationParser.parse(this.totalLaborCostFormula);
    this.totalLaborCost = equation.evaluate(this.equation);
  }

  calculateOverheadCost() {
    const equation = this.equationParser.parse(this.totalOverheadCostFormula);
    this.totalOverheadCost = equation.evaluate(this.equation);
  }

  calculateProfit() {
    const equation = this.equationParser.parse(this.totalProfitFormula);
    this.totalProfit = equation.evaluate(this.equation);
  }

  calculateTotal() {
    const equation = this.equationParser.parse(this.totalFormula);
    this.total = equation.evaluate(this.equation);
  }
}
