export const PAGE_NAME = {
  account: 'ACCOUNT_PAGE',
  product: 'PRODUCT_PAGE',
  order: 'ORDER_VIEW_PAGE',
  orderDetails: 'ORDER_DETAILS_PAGE',
  workOrder: 'WORK_ORDER_PAGE',
  openOrderReport: 'OPEN_ORDER_REPORT_PAGE',
  stoneOpenOrderReport: 'STONE_OPEN_ORDER_REPORT_PAGE',
  stone: 'STONE_PAGE',
  chain: 'CHAIN_PAGE',
  finding: 'FINDING_PAGE',
  metalGrain: 'METAL_GRAIN_PAGE',
  enamel: 'ENAMEL_PAGE',
  otherComponents: 'OTHER_COMPONENTS_PAGE',
  nonPrecious: 'NON_PRECIOUS_PAGE',
  manufacturedMaterial: 'MANUFACTURED_MATERIAL_PAGE',
  supplier: 'SUPPLIER_PAGE',
  customer: 'CUSTOMER_PAGE',
  inventory: 'INVENTORY_LOG_PAGE',
  invoicingAndShipping: 'INVOICING_AND_SHIPPING_PAGE',
  reporting: 'REPORTING_PAGE',
  itemWeights: 'REPORTING_ITEM_WEIGHTS_PAGE',
  workOrderTracking: 'WORK_ORDER_TRACKING_PAGE',
  workOrderMovement: 'WORK_ORDER_MOVEMENT_PAGE',
  workOrderLabelPrinting: 'WORK_ORDER_LABEL_PRINTING_PAGE',
  productQuery: 'PRODUCT_QUERY_PAGE',
  equation: 'EQUATION_PAGE',
  priceDefaults: 'PRICE_DEFAULTS_PAGE',
  metalOverheadEquations: 'METAL_OVERHEAD_EQUATIONS_PAGE',
  reports: 'REPORTS_PAGE',
  productPricing: 'PRODUCT_PRICING_PAGE',
};

export const PAGES = [
  {
    pageName: PAGE_NAME.account,
    label: 'Accounts',
    disabled: false,
    customAccessLevel: ['SuperUser'],
  },
  {
    pageName: PAGE_NAME.product,
    label: 'Products',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.order,
    label: 'Purchase Orders',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.workOrder,
    label: 'Work Orders',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.reporting,
    label: 'Reporting',
    disabled: false,
    customAccessLevel: ['SuperUser', 'User'],
  },
  {
    pageName: PAGE_NAME.openOrderReport,
    label: 'Open Order Report',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.orderDetails,
    label: 'Order Details',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.invoicingAndShipping,
    label: 'Invoicing And Shipping',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.stone,
    label: 'Stones',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.finding,
    label: 'Findings',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.enamel,
    label: 'Enamel',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.otherComponents,
    label: 'Other Components',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.supplier,
    label: 'Supplier',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.customer,
    label: 'Customer',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.workOrderTracking,
    label: 'Work Order Tracking',
    disabled: false,
    customAccessLevel: ['SuperUser', 'User'],
  },
  {
    pageName: PAGE_NAME.stoneOpenOrderReport,
    label: 'Stone Open Order Report',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.workOrderMovement,
    label: 'Work Order Movement',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.workOrderLabelPrinting,
    label: 'Package Label Printing',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.productQuery,
    label: 'Product Query',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.chain,
    label: 'Chain',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.itemWeights,
    label: 'Item Weights',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.equation,
    label: 'Equations',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.priceDefaults,
    label: 'Price Defaults',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.productPricing,
    label: 'Product Pricing',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.metalOverheadEquations,
    label: 'Metal Overhead Equations',
    disabled: false,
  },
  {
    pageName: PAGE_NAME.reports,
    label: 'Reports',
    disabled: false,
  },
];
