<div class="customer-price-defaults-section">
  <button
    class="riva-button"
    style="width: 250px; margin-bottom: 16px"
    (click)="onCreateNewDefault()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#68516d"
      width="18"
      height="18"
      stroke-width="2"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="currentColor"
    >
      <path d="M12 5l0 14"></path>
      <path d="M5 12l14 0"></path>
    </svg>
    New Customer Default
  </button>
  <div class="table-container">
    <table
      mat-table
      matSort
      [dataSource]="customers"
      matSortActive="sortOrder"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef>Customer</th>
        <td mat-cell *matCellDef="let element">
          <nz-select
            [(ngModel)]="element.customerID"
            *ngIf="element.isNew"
            style="width: 100%"
          >
            <nz-option
              *ngFor="let option of customerOptions"
              [nzValue]="option.customerID"
              [nzLabel]="option.companyName"
            ></nz-option>
          </nz-select>
          <span *ngIf="!element.isNew">{{ element.companyName }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="stdLaborPerMinCost">
        <th mat-header-cell *matHeaderCellDef>STD Labor Per Min Cost</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.stdLaborPerMinCost"
            [disabled]="isSaving"
            [nzPlaceHolder]="defaultForCustomer.stdLaborPerMinCost"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="settingLaborPerMinCost">
        <th mat-header-cell *matHeaderCellDef>Setting Labor Per Min Cost</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.settingLaborPerMinCost"
            [disabled]="isSaving"
            [nzPlaceHolder]="defaultForCustomer.settingLaborPerMinCost"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="ohPercent">
        <th mat-header-cell *matHeaderCellDef>Overhead Percentage</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.ohPercent"
            [disabled]="isSaving"
            [nzPlaceHolder]="defaultForCustomer.ohPercent"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="profitPercent">
        <th mat-header-cell *matHeaderCellDef>Profit Percentage</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.profitPercent"
            [nzPlaceHolder]="defaultForCustomer.profitPercent"
            [disabled]="isSaving"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="emptyCell">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="6">No Available Data</td>
      </tr>
    </table>
  </div>
  <div style="display: flex; justify-content: flex-end">
    <button
      (click)="onSaveDefaults()"
      class="btn btn-primary"
      style="width: 300px"
      [disabled]="isSaving"
    >
      Save All Defaults
    </button>
  </div>
</div>
