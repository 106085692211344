import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import uniqBy from 'lodash/uniqBy';
import { AlertService } from 'src/app/custom/_alert';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { MaterialCode } from 'src/app/models/material-code';
import { Orders, OrdersDetails } from 'src/app/models/orders.model';
import {
  Product,
  ProductEnamel,
  ProductVariation,
  ProductsRouting,
  WorkOrderEnamel,
} from 'src/app/models/product';
import {
  WorkOrder,
  WorkOrderDetail,
  WorkOrderDetailDisplay,
  WorkOrderDetailPrint,
  WorkOrderDetailsForPrint,
  WorkOrderPrintReport,
  WorkOrderReport,
  WorkOrderUI,
} from 'src/app/models/work-order';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { WORK_ORDER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/work-order-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { Customers } from 'src/app/models/customer';
import { ProductFindings } from 'src/app/models/findings.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { CustomerService } from 'src/app/services/customer.service';
import { EnamelService } from 'src/app/services/enamel.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductsRoutingService } from 'src/app/services/products-routing.service';
import { SubItemService } from 'src/app/services/sub-item.service';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { GenericComponent, Guid } from '../generic/generic.component';
import { ProductService } from './../../services/product.service';
import {
  ProductStone,
  StoneSizeOrderDetails,
} from './../riva-gems/riva-gems.model';
import { ReceivingItemDialogComponent } from './receiving-item-dialog/receiving-item-dialog.component';
import { WorkOrderPrintDialogComponent } from './work-order-print/work-order-print-dialog.component';
import { WorkOrdersReleaseComponent } from './work-orders-release.component';

export const getOrderDetailsSizeDisplayText = ({
  dimension1,
  dimension2,
  dimension3,
}: StoneSizeOrderDetails) => {
  const dimensions = [];
  if (dimension1) dimensions.push(`${dimension1}mm`);
  if (dimension2) dimensions.push(`${dimension2}mm`);
  if (dimension3) dimensions.push(`${dimension3}mm`);
  return dimensions.join(' x ');
};

@Component({
  selector: 'app-work-order-view',
  templateUrl: './work-order-view.component.html',
  styleUrls: ['./work-order-view.component.scss'],
})
export class WorkOrderViewComponent
  extends GenericComponent
  implements OnInit, AfterViewInit
{
  constructor(
    loadService: LoadService,
    alertService: AlertService,
    public dialog: MatDialog,
    private enamelService: EnamelService,
    private productBomService: ProductBomService,
    private orderService: OrderService,
    private workOrderService: WorkOrderService,
    private subItemService: SubItemService,
    private productService: ProductService,
    private productsRoutingService: ProductsRoutingService,
    private userPermissionService: UserPermissionService,
    private modalService: NgbModal,
    private customerService: CustomerService,
  ) {
    super(loadService, alertService);
    this.userPermissionService.checkPagePermission(PAGE_NAME.workOrder);
  }

  ngOnInit(): void {
    this.loadWorkOrders();
    this.loadService.reloadWorkOrders = () => {
      this.loadWorkOrders();
    };
    this.customerService.getList().subscribe((data) => {
      this.customers = orderBy(data, 'companyName');
    });
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('confirmationWorkOrder')
  public readonly confirmationWorkOrder!: SwalComponent;

  ngAfterViewInit() {
    this.workOrders.sort = this.sort;
    this.workOrders.paginator = this.paginator;
  }
  workOrderFeatureKey = WORK_ORDER_FEATURE_KEY;

  workOrderReports: WorkOrderReport[] = [];
  filtered_workOrderReports: WorkOrderReport[] = [];
  select_wko: number = 1;

  orders: Orders[] = [];
  selectedOrderId: number = 0;
  selectedOrderItem: number = 0;
  selectedOrderDetail: OrdersDetails = new OrdersDetails();
  workOrder: WorkOrder = new WorkOrder();
  workOrderDetail: WorkOrderDetail = new WorkOrderDetail();
  materialCodes: MaterialCode[] = [];
  workOrderDetailDisplay: WorkOrderDetailDisplay = new WorkOrderDetailDisplay();
  detailDisplays: WorkOrderDetailDisplay[] = [];
  searchOrder: string = '';
  filteredOrders: Orders[] = [];
  enamelVariations: ProductVariation[] = [];
  productEnamels: ProductEnamel[] = [];
  productFindings: ProductFindings[] = [];
  enamels: WorkOrderEnamel[] = [];
  displayedColumns: string[] = [
    'workOrdersId',
    'orderType',
    'qtyTotal',
    'picturePath',
    'materialCode',
    'productSku',
    'poInternal',
    'customerName',
    'createdDate',
    'dueDate',
    'printedDate',
    'releasedDate',
    'dateClosed',
    'printAction',
  ];
  filterBy: string = '';
  filterByStatus: string = 'open';
  filterByReleaseStatus: string = 'all';
  filterByPrintStatus: string = 'all';
  filterByCustomer: number = 0;
  workOrders = new MatTableDataSource<WorkOrderUI>([]);
  currentFilter: string = 'status';
  karatItems = [...Array(6).keys()];
  isPrintedReport = false;
  selectedWorkOrder: {
    dueDate: string;
    printedDate: string;
    releasedDate: string;
    receivedDate: string;
  };

  workOrderForPrint: WorkOrderDetailsForPrint = {} as WorkOrderDetailsForPrint;
  subItems: SubItem[] = [];
  productRoutings: ProductsRouting[] = [];
  productStonesConstants: ProductStone[] = [];
  productStonesVariations: ProductStone[] = [];
  customers: Customers[] = [];

  confirmation: {
    icon: string;
    title: string;
    description: string;
    timer?: number;
  } = {
    icon: 'question',
    title: '',
    description: '',
  };
  workOrderDueDate: Date | null;
  isLoadingWorkOrder = false;

  loadWorkOrders() {
    this.isLoadingWorkOrder = true;
    this.workOrderService.getWorkOrders().subscribe((result) => {
      this.workOrders.data = result;
      this.isLoadingWorkOrder = false;
    });
    this.onFilter(this.currentFilter);
    this.workOrders.filterPredicate = (
      data: WorkOrderUI,
      filterValue: string,
    ) => {
      const { status, printStatus, releaseStatus, search, customer } =
        JSON.parse(filterValue) ?? {};

      const statusFilter =
        status === 'all' ||
        (status === 'closed' &&
          data.isFullyClosed &&
          data.dateClosed != null) ||
        (status === 'open' &&
          (data.isPartiallyClosed || data.dateClosed == null));
      const printStatusFilter =
        printStatus === 'all' ||
        (printStatus === 'printed' && data.printedDate !== null) ||
        (printStatus === 'not-printed' && data.printedDate == null);
      const releaseStatusFilter =
        releaseStatus === 'all' ||
        (releaseStatus === 'released' && data.releasedDate !== null) ||
        (releaseStatus === 'unreleased' && data.releasedDate == null);
      const searchText = search?.trim()?.toLowerCase();
      const searchFilter =
        data.workOrdersId.toString().toLowerCase().includes(searchText) ||
        data.poInternal?.toLowerCase().includes(searchText) ||
        data.qtyTotal.toString()?.toLowerCase().includes(searchText) ||
        data.productSku?.toLowerCase().includes(searchText) ||
        data.materialCode?.toLowerCase().includes(searchText) ||
        data.materialDescription?.toLowerCase().includes(searchText);
      const customerFilter = customer === 0 || data.customerId === customer;

      return (
        statusFilter &&
        printStatusFilter &&
        releaseStatusFilter &&
        searchFilter &&
        customerFilter
      );
    };
    this.workOrders.sortingDataAccessor = (
      data: any,
      sortHeaderId: string,
    ): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }

      return data[sortHeaderId];
    };
  }

  onFilter(filter: string) {
    this.currentFilter = filter;
    this.workOrders.filter = JSON.stringify({
      search: this.searchOrder,
      status: this.filterByStatus,
      releaseStatus: this.filterByReleaseStatus,
      printStatus: this.filterByPrintStatus,
      customer: this.filterByCustomer,
    });
  }

  onScanWorkOrder(action: string) {
    const dialogRef = this.dialog.open(WorkOrdersReleaseComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: {
        workOrders: [...this.workOrders.data],
        action,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (action === 'release') {
          this.workOrderService.setWorkOrderRelease(result).subscribe(
            () => {
              this.loadWorkOrders();
              this.confirmation = {
                title: 'Work Order Released',
                description: `Successfully released work order number '${result}'`,
                icon: 'success',
                timer: 1000,
              };
              setTimeout(() => {
                this.confirmationWorkOrder.fire();
              }, 100);
            },
            () => {
              this.confirmation = {
                title: 'Release Failed',
                description: `Unable to release work order number '${result}'. Please contact the administration`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationWorkOrder.fire();
              }, 100);
            },
          );
        }
        if (action === 'close') {
          this.workOrderService
            .getUnClosedWorkOrderItems(result)
            .subscribe((data) => {
              if (data.length === 0) {
                this.confirmation = {
                  title: 'Received Work Order',
                  description: `Work order number ${result} already received.`,
                  icon: 'error',
                };
                setTimeout(() => {
                  this.confirmationWorkOrder.fire();
                }, 100);
                return;
              }
              // const [item] = data ?? [];
              // if (data.length === 1 && item?.qty === 1) {
              //   this.workOrderService
              //     .receiveWorkOrderItems({
              //       workOrdersID: item.workOrdersID,
              //       items: [
              //         {
              //           workOrdersDetailsID: item.workOrderDetailsID,
              //           qtyToClose: item.qty,
              //         },
              //       ],
              //     })
              //     .subscribe(
              //       () => {
              //         this.receivedWorkOrderResponse({
              //           isSuccessful: true,
              //           received: 1,
              //           total: 1,
              //           hasError: false,
              //           workOrderId: result,
              //         });
              //       },
              //       () => {
              //         this.receivedWorkOrderResponse({
              //           isSuccessful: false,
              //           received: 1,
              //           total: 1,
              //           hasError: true,
              //           workOrderId: result,
              //         });
              //       },
              //     );
              //   return;
              // }

              const dialogResponse = this.dialog.open(
                ReceivingItemDialogComponent,
                {
                  disableClose: true,
                  maxWidth: '900px',
                  width: '100%',
                  autoFocus: false,
                  data: {
                    items: data,
                    workOrderId: result,
                  },
                },
              );

              dialogResponse.afterClosed().subscribe((res) => {
                this.receivedWorkOrderResponse({ ...res, workOrderId: result });
              });
            });
        }
      }
    });
  }

  receivedWorkOrderResponse({
    isSuccessful,
    hasError,
    received,
    total,
    workOrderId,
  }) {
    if (isSuccessful) {
      this.loadWorkOrders();
      const description =
        received === total
          ? `Successfully received work order number '${workOrderId}'`
          : `Successfully received ${received} out of ${total} items from work order number '${workOrderId}'`;
      this.confirmation = {
        title: 'Work Order Received',
        description,
        icon: 'success',
        timer: 1000,
      };
      setTimeout(() => {
        this.confirmationWorkOrder.fire();
      }, 100);
      return;
    }

    if (hasError) {
      this.confirmation = {
        title: 'Close Failed',
        description: `Unable to close work order number '${workOrderId}'. Please contact the administration`,
        icon: 'error',
      };
      setTimeout(() => {
        this.confirmationWorkOrder.fire();
      }, 100);
    }
  }

  onSelectOrder(order: Orders) {
    this.selectedOrderId = order.ordersId;
    this.selectedOrderItem = 0;
  }

  filterWKO() {
    if (this.select_wko == 0) {
      this.filtered_workOrderReports = this.workOrderReports;
    } else if (this.select_wko == 1) {
      this.filtered_workOrderReports = this.workOrderReports.filter(
        (x) => x.closedDate == null,
      );
    } else if (this.select_wko == 2) {
      this.filtered_workOrderReports = this.workOrderReports.filter(
        (x) => x.closedDate != null,
      );
    }
  }

  addToWorkOrder() {
    this.selectedOrderItem = 0;
    this.workOrder.workOrderDetails.push(
      this.workOrderDetailDisplay.workOrderDetail,
    );
    let map = new WorkOrderDetailDisplay();
    map.productName = this.workOrderDetailDisplay.productName;
    map.photo = this.workOrderDetailDisplay.photo;
    map.material = this.workOrderDetailDisplay.material;
    map.size = this.workOrderDetailDisplay.size;
    map.workOrderDetail = this.workOrderDetailDisplay.workOrderDetail;
    this.detailDisplays.push(map);
    this.showOrderDetail();
  }

  removeDetail(
    detailDisplay: WorkOrderDetailDisplay,
    orderDetail: WorkOrderDetail,
  ) {
    this.workOrder.workOrderDetails = this.workOrder.workOrderDetails.filter(
      (x) => x != orderDetail,
    );
    this.detailDisplays = this.detailDisplays.filter((x) => x != detailDisplay);
  }

  saveWorkOrder() {
    this.workOrderService.post(this.workOrder, '').subscribe(
      (result) => {
        this.alertService.success('Created work order');
        this.revertWorkOrder();
        this.workOrderService.getListReport().subscribe((result) => {
          this.workOrderReports = result;
        });
      },
      (error) => {
        this.alertService.error('Unable to save work order.');
      },
    );
  }

  revertWorkOrder() {
    this.selectedOrderId = 0;
    this.selectedOrderItem = 0;
    this.detailDisplays = [];
    this.workOrder = new WorkOrder();
    this.workOrderDetailDisplay = new WorkOrderDetailDisplay();
  }

  get orderItems() {
    let orderItems = [];
    if (this.selectedOrderId != 0) {
      orderItems = this.orders.find(
        (x) => x.ordersId == this.selectedOrderId,
      ).ordersDetails;
      this.detailDisplays.forEach((element) => {
        orderItems = orderItems.filter(
          (x) => x.ordersDetailsId != element.workOrderDetail.ordersDetailsId,
        );
      });
    }
    return orderItems;
  }

  getMaterial() {
    let x = this.materialCodes.find(
      (x) =>
        x.materialCodeId ==
        this.selectedOrderDetail.productsInfo.materialCodeId,
    );
    if (x !== undefined) {
      return x.description;
    }
    return '';
  }

  showOrderDetail() {
    this.workOrderDetailDisplay = new WorkOrderDetailDisplay();
    this.selectedOrderDetail = new OrdersDetails();
    this.selectedOrderDetail.products = new Product();
    if (this.selectedOrderId != 0) {
      if (
        this.orders.find((x) => x.ordersId == this.selectedOrderId)
          .ordersDetails.length != 0
      ) {
        let x = this.orders.find((x) => x.ordersId == this.selectedOrderId);
        this.selectedOrderDetail = x.ordersDetails.find(
          (x) => x.ordersDetailsId == this.selectedOrderItem,
        );
        this.workOrderDetailDisplay.workOrderDetail.ordersDetailsId =
          this.selectedOrderDetail.ordersDetailsId;
        this.workOrderDetailDisplay.workOrderDetail.wgtpitem =
          this.selectedOrderDetail.productsInfo.weight;
        this.workOrderDetailDisplay.material = this.getMaterial();
        this.workOrderDetailDisplay.size =
          this.selectedOrderDetail.productsInfo.size;
        this.workOrderDetailDisplay.productName =
          this.selectedOrderDetail.products.productName;
        this.workOrderDetailDisplay.photo =
          this.selectedOrderDetail.products.picPath;
      }
    }
  }

  openWKOs: WorkOrderReport[] = [];
  openWKO: WorkOrderReport = new WorkOrderReport();
  openWKO_details: WorkOrderPrintReport[] = [];

  setOpenWorkOrders() {
    this.openWKOs = this.workOrderReports.filter((x) => x.closedDate == null);
    this.filterOpenWKOs();
  }

  selectOpenWKO(wko: WorkOrderReport) {
    this.openWKO = wko;
    this.openWKO_details = wko.workOrderPrintReports;
  }

  searchText: number = 0;
  filtered_openWKOs: WorkOrderReport[] = [];
  filterOpenWKOs() {
    this.searchText = Number(this.searchText);
    this.filtered_openWKOs = this.openWKOs.filter((x) =>
      x.workOrdersId.toString().includes(this.searchText.toString()),
    );
    if (this.searchText == 0) {
      this.filtered_openWKOs = this.openWKOs;
    }
  }

  resetOpenWKO() {
    this.openWKO = new WorkOrderReport();
    this.openWKO_details = [];
  }

  order: Orders = new Orders();
  workOrderPrintReport: WorkOrderPrintReport = new WorkOrderPrintReport();

  selectPrintReport(reportItem: WorkOrderPrintReport) {
    this.workOrderPrintReport = reportItem;
    this.workOrderPrintReport.guid = Guid.newGuid();
    this.orderService
      .getByOrderDetailsId(reportItem.workOrderDetailsId)
      .subscribe((result) => {
        this.order = new Orders();
        this.order = result;
      });
  }

  onPrint(workOrderId: number) {
    this.workOrderService
      .getWorkOrderPrintDetail(workOrderId)
      .subscribe((data: { workOrderPrintReports: WorkOrderPrintReport[] }) => {
        const [reportItem] = data.workOrderPrintReports;
        this.selectPrintReport(reportItem);
      });
  }

  onPrintSelectedWorkOrder(workOrder, content) {
    const { workOrdersId, printedDate } = workOrder;
    this.selectedWorkOrder = {
      dueDate: workOrder.dueDate
        ? DateTime.fromJSDate(new Date(workOrder.dueDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      printedDate: workOrder.printedDate
        ? DateTime.fromJSDate(new Date(workOrder.printedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      releasedDate: workOrder.releasedDate
        ? DateTime.fromJSDate(new Date(workOrder.releasedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      receivedDate: workOrder.dateClosed
        ? DateTime.fromJSDate(new Date(workOrder.dateClosed)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
    };
    this.isPrintedReport = !!printedDate;
    this.modalOpen(content);
    this.workOrderService
      .getWorkOrderForPrint(workOrdersId)
      .subscribe((data) => {
        const sizes = data.workOrderDetails.reduce(
          (accum, details) => {
            const isNumber = !Number.isNaN(Number(details.orderDetails?.size));
            if (isNumber) {
              accum.numeric = [...accum.numeric, details];
            } else {
              accum.alpha = [...accum.alpha, details];
            }
            return accum;
          },
          {
            alpha: [],
            numeric: [],
          },
        );

        this.workOrderForPrint = {
          ...data,
          workOrderDetails: [
            ...orderBy(
              sizes.numeric,
              (s: WorkOrderDetailPrint) => s.orderDetails.size,
            ),
            ...orderBy(
              sizes.alpha,
              (s: WorkOrderDetailPrint) => s.orderDetails.size,
            ),
          ],
        };

        if (this.workOrderForPrint.workOrderDetails.length > 1) {
          const workOrdersForDueDate =
            this.workOrderForPrint.workOrderDetails.map((w) => ({
              ...w,
              dueDate: DateTime.fromJSDate(
                new Date(w.orderDetails.dueDate),
              ).toJSDate(),
            }));
          const [workOrderItem] = orderBy(workOrdersForDueDate, ['dueDate']);
          this.workOrderDueDate = workOrderItem.dueDate;
        } else {
          const [workOrderItem] = this.workOrderForPrint.workOrderDetails;
          this.workOrderDueDate = workOrderItem?.orderDetails.dueDate
            ? new Date(workOrderItem?.orderDetails.dueDate)
            : null;
        }

        this.productStonesVariations =
          this.workOrderForPrint.workOrderDetails.reduce(
            (variations, detail) => {
              if (!detail.orderDetails.stone?.ordersDetailsStonesID)
                return variations;
              const stone = {
                ...(detail.orderDetails.stone ?? {}),
                size: detail.orderDetails.stone?.size
                  ? getOrderDetailsSizeDisplayText(
                      detail.orderDetails.stone?.size as StoneSizeOrderDetails,
                    )
                  : '',
              };
              return [...variations, stone];
            },
            [],
          );

        this.getSubItems(data.product.productId);
        this.getProductRouting(data.product.productId);
        this.getProductEnamels(data.product.productId);
        this.getProductFindings(data.product.productId);
        this.getProductPictures(data.product.productId);
        this.getProductStones(data.product.productId);
      });
  }

  onPrintWorkOrder(workOrder) {
    const ref = this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder,
      },
    });

    ref.afterClosed().subscribe((isReload) => {
      if (isReload) {
        this.loadWorkOrders();
      }
    });
  }

  getProductStones(productId) {
    this.productBomService
      .getProductStones(productId)
      .subscribe((productStones) => {
        this.productStonesConstants = productStones.filter((s) => !s.variation);
      });
  }

  getProductPictures(productId) {
    this.productService
      .getProductImageList(productId)
      .subscribe((data = []) => {
        this.workOrderForPrint.product.picture = data[0]?.fileName;
      });
  }

  getProductFindings(productId) {
    this.productBomService.getProductFindings(productId).subscribe((data) => {
      this.productFindings = data;
    });
  }

  getSubItems(productId) {
    this.subItemService.getSubItems(productId).subscribe((data) => {
      const availableSize = this.workOrderForPrint.workOrderDetails.map(
        (detail) => detail.orderDetails.size,
      );
      this.subItems = data?.map((item) => ({
        ...item,
        subItemsInfo: item.subItemsInfo
          ?.filter((s) => availableSize.includes(s.size))
          ?.map((subItemInfo) => ({
            ...subItemInfo,
            mfgMethodDetails:
              subItemInfo?.mfgMethod === 3 ? '' : subItemInfo?.mfgMethodDetails,
            stlPath:
              subItemInfo?.mfgMethod === 3
                ? encodeURI(subItemInfo?.mfgMethodDetails)
                : '',
          })),
      }));
    });
  }

  getEnamelVariations(productId) {
    this.productService.getProductVariations(productId).subscribe((data) => {
      this.enamelVariations = data.filter((variation) =>
        this.workOrderForPrint.workOrderDetails.some(
          (workOrder) =>
            workOrder.orderDetails?.enamel?.enamelSku === variation.enamelSKU,
        ),
      );
    });
  }

  getProductEnamels(productId) {
    forkJoin([
      this.enamelService.getList(),
      this.productBomService.getProductEnamel(productId),
    ])
      .pipe(
        map(([enamels, enamelList]) => {
          const enamelVariations = enamelList
            .filter((e) => e.variation)
            .map((v) => {
              const enamel =
                enamels.find((e) => e.enamelId === v.enamelID) ?? {};
              return { ...enamel, ...v };
            });
          const productEnamels = enamelList
            .filter((e) => !e.variation)
            .map((p) => {
              const enamel =
                enamels.find((e) => e.enamelId === p.enamelID) ?? {};
              return { ...enamel, ...p };
            });
          return { productEnamels, enamelVariations };
        }),
      )
      .subscribe(({ productEnamels, enamelVariations }) => {
        this.productEnamels = productEnamels.filter((enamel) =>
          this.workOrderForPrint.workOrderDetails.some(
            (workOrder) =>
              workOrder.orderDetails?.enamel?.enamelSku === enamel.enamelSku,
          ),
        );
        const variations = enamelVariations.filter((enamel) =>
          this.workOrderForPrint.workOrderDetails.some(
            (workOrder) =>
              workOrder.orderDetails?.enamel?.enamelSku === enamel.enamelSku,
          ),
        );

        this.enamels = uniqBy(
          [...productEnamels, ...variations].map(
            ({ enamelName, colorHex, enamelSKU, enamelSku }) => ({
              enamelName,
              colorHex,
              enamelSKU: enamelSKU ?? enamelSku,
            }),
          ),
          'enamelSKU',
        );
      });
  }

  getProductRouting(productId) {
    this.productsRoutingService
      .getProductRoutings(productId)
      .subscribe((data) => {
        this.productRoutings = data;
      });
  }

  onSetPrintedDate() {
    if (!this.workOrderForPrint.workOrder.workOrdersId) return;
    this.workOrderService
      .setWorkOrderPrint(this.workOrderForPrint.workOrder.workOrdersId)
      .subscribe(() => {
        this.loadWorkOrders();
      });
  }
  onSetNotPrinted() {
    if (!this.workOrderForPrint.workOrder.workOrdersId) return;
    this.workOrderService
      .setWorkOrderNotPrint(this.workOrderForPrint.workOrder.workOrdersId)
      .subscribe(() => {
        this.loadWorkOrders();
        this.isPrintedReport = false;
        this.selectedWorkOrder.printedDate = '';
      });
  }

  closeWko(wkoId: number) {
    this.workOrderService.closeWorkOrder(wkoId).subscribe((result) => {
      this.workOrderService.getListReport().subscribe((result) => {
        this.workOrderReports = result;
        this.setOpenWorkOrders();
        this.resetOpenWKO();
      });
    });
  }

  closeWkoDetail(wkoDetail: WorkOrderPrintReport) {
    this.workOrderService
      .closeWorkOrderDetail(wkoDetail.workOrderDetailsId)
      .subscribe((result) => {
        wkoDetail.qtyclosed = wkoDetail.qtyinit;
        this.workOrderService.getListReport().subscribe((result) => {
          this.workOrderReports = result;
          this.setOpenWorkOrders();
          let x = this.openWKOs.find(
            (x) => x.workOrdersId == this.openWKO.workOrdersId,
          );
          if (x != undefined) {
            this.selectOpenWKO(x);
          } else {
            this.resetOpenWKO();
          }
        });
      });
  }

  routingList(routing: string) {
    let list = [];
    if (!this.checkStringIfEmpty(routing)) {
      list = JSON.parse(routing);
    }
    return list;
  }

  bomList(bom: string) {
    let list = [];
    if (!this.checkStringIfEmpty(bom)) {
      list = JSON.parse(bom);
    }
    return list;
  }

  // Modal section
  closeResult = '';

  modalActive: boolean = false;

  return(value) {
    return value;
  }

  modalOpen(content) {
    this.modalActive = true;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  private getDismissReason(reason: any): string {
    this.modalActive = false;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  filterOrders(search) {
    if (!search) {
      this.filteredOrders = [...this.orders];
      return;
    }
    this.filteredOrders = this.orders.filter(
      (order) => order.pointernal?.toLocaleLowerCase().indexOf(search) > -1,
    );
  }
  getFontSize(text = '') {
    let fontSize = 45;
    if (!text) return `${fontSize}px`;
    switch (true) {
      case text.length <= 25:
        fontSize = 45;
        break;
      case text.length <= 45:
        const size = text.length - 25;
        fontSize = 45 - size;
        break;
      case text.length <= 65:
        fontSize = Math.abs((text.length - 45) * 0.35 - 25);
        break;
      default:
        fontSize = 14;
        break;
    }
    return `${fontSize}px`;
  }
}
