import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Customers } from 'src/app/models/customer';
import { StoneItem } from './stone-item';

@Component({
  selector: 'stone-receiving-dialog',
  templateUrl: './stone-receiving-dialog.component.html',
  styleUrls: ['./stone-receiving-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StoneReceivingDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'picturePath',
    'stoneType',
    'stoneCut',
    'stoneClarity',
    'stoneColor',
    'stoneSize',
    'labGrown',
    'traceable',
    'stoneQty',
    'carat',
    'customerProvided',
  ];
  items: StoneItem[] = [];
  isSelectedAll = true;
  isSomeSelected = false;
  isReceiving = false;
  requiredWeight = true;
  focusItemIndex = 0;
  customersList: Customers[];

  constructor(
    public dialogRef: MatDialogRef<StoneReceivingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { items: StoneItem[]; workOrderId: number },
  ) {}

  ngOnInit(): void {}
  onReleaseStones() {
    this.dialogRef.close(this.props.items ?? []);
  }
  onCaratChange(item: StoneItem) {
    item.totalStoneQty = !item.carat
      ? item.maxQty
      : Math.round(item.carat / (item.caratsPerUnit ?? 0));
  }

  onQuantityChange(item: StoneItem) {
    item.carat = !item.totalStoneQty
      ? item.maxCarat
      : parseFloat((item.totalStoneQty * item.caratsPerUnit).toFixed(4));
  }

  get isFormValid() {
    return this.props.items.some((i) => i.totalStoneQty >= 0);
  }
}
