<div class="product-pricing-page">
  <div class="product-pricing-header">
    <mat-form-field appearance="fill" style="width: 500px">
      <mat-label>Customer</mat-label>
      <mat-select
        placeholder="Customer"
        [(ngModel)]="selectedCustomer"
        (ngModelChange)="onGetProductsByCustomer()"
      >
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Search"
            [hideClearSearchButton]="true"
            [formControl]="customerFilterCtrl"
            noEntriesFoundLabel="Not found"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let customer of filteredCustomers"
          [value]="customer.custIdno"
        >
          [{{ customer.customerId }}] {{ customer.companyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      class="btn btn-primary"
      style="width: 250px"
      [disabled]="productPricingList.data.length === 0"
      (click)="onSaveProductPricing()"
    >
      Save Pricing
    </button>
  </div>
  <div class="table-container">
    <table
      mat-table
      multiTemplateDataRows
      matSort
      [dataSource]="productPricingList"
      matSortActive="productName"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="expander">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          class="expander-column"
          (click)="updateExpandedPricing(element)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-square-plus"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#a762b7"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            [ngClass]="{ hidden: expandedProductPricing[element.productsID] }"
            *ngIf="!element.isPricingPerSize"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
            />
            <path d="M9 12l6 0" />
            <path d="M12 9l0 6" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-square-minus"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#a762b7"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            [ngClass]="{ hidden: !expandedProductPricing[element.productsID] }"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
            />
            <path d="M9 12l6 0" />
          </svg>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef>Pic</th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 58px;
              padding: 5px;
              height: 58px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            "
          >
            <img
              style="width: 100%; height: 100%"
              [src]="element.picture"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="productName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by productName"
        >
          Product Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.productName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="materials">
        <th mat-header-cell *matHeaderCellDef>Metal</th>
        <td mat-cell *matCellDef="let element">
          <nz-select
            style="width: 100%"
            [(ngModel)]="element.materialsCodeID"
            (ngModelChange)="onChangeMaterial(element)"
          >
            <nz-option
              *ngFor="let option of element.materials"
              [nzValue]="option.materialCodeId"
              [nzLabel]="option.code"
            ></nz-option>
          </nz-select>
        </td>
      </ng-container>
      <ng-container matColumnDef="metalWeight">
        <th mat-header-cell *matHeaderCellDef>Metal Weight</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.metalWeight"
            [disabled]="isSaving"
            [nzMax]="9999.9999"
            style="width: 100%"
            (nzBlur)="onCalculateEstimatedPricing(element)"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="jewelerTime">
        <th mat-header-cell *matHeaderCellDef>Jeweler Time</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.jewelerTime"
            [disabled]="isSaving"
            [nzMax]="9999.99"
            style="width: 100%"
            (nzBlur)="onCalculateEstimatedPricing(element)"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="setterTime">
        <th mat-header-cell *matHeaderCellDef>Setter Time</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.setterTime"
            [disabled]="isSaving"
            [nzMax]="9999.99"
            style="width: 100%"
            (nzBlur)="onCalculateEstimatedPricing(element)"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="chainPrice">
        <th mat-header-cell *matHeaderCellDef>Chain Price</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.chainPrice"
            [disabled]="isSaving"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
            style="width: 100%"
            (nzBlur)="onCalculateEstimatedPricing(element)"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="stonePrice">
        <th mat-header-cell *matHeaderCellDef>Stone Price</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.stonePrice"
            [disabled]="isSaving"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
            style="width: 100%"
            (nzBlur)="onCalculateEstimatedPricing(element)"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="findingsPrice">
        <th mat-header-cell *matHeaderCellDef>Findings Price</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.findingsPrice"
            [disabled]="isSaving"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
            style="width: 100%"
            (nzBlur)="onCalculateEstimatedPricing(element)"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="estimatedPrice">
        <th mat-header-cell *matHeaderCellDef>Estimated Price</th>
        <td mat-cell *matCellDef="let element" style="text-align: right">
          {{ element.estimatedPrice ?? 0 | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="completeOverridePrice">
        <th mat-header-cell *matHeaderCellDef>Complete Override Price</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.completeOverridePrice"
            [disabled]="isSaving"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="laborOverridePrice">
        <th mat-header-cell *matHeaderCellDef>Labor Override Price</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.laborOverridePrice"
            [disabled]="isSaving"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="emptyCell">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="12">No Available Data</td>
      </tr>

      <ng-container matColumnDef="expandedDetail">
        <td
          class="expandable-detail"
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="expandable-element"
            [@detailExpand]="
              expandedProductPricing[element.productsID]
                ? 'expanded'
                : 'collapsed'
            "
          >
            <div class="expandable-container">
              <div style="align-self: flex-end; justify-self: center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-square-rounded-plus-filled"
                  width="44"
                  height="44"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#a762b7"
                  fill="#a762b7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style="cursor: pointer"
                  (click)="onAddSubPricing(element)"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm0 6a1 1 0 0 0 -1 1v2h-2l-.117 .007a1 1 0 0 0 .117 1.993h2v2l.007 .117a1 1 0 0 0 1.993 -.117v-2h2l.117 -.007a1 1 0 0 0 -.117 -1.993h-2v-2l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                    fill="#a762b7"
                    stroke-width="0"
                  />
                </svg>
              </div>
              <div style="width: 100%">
                <div class="container-row container-header">
                  <div class="container-column">Product Size</div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                  <div class="container-column"></div>
                </div>
                <div
                  class="container-row"
                  *ngFor="let item of element.productSubPricing"
                >
                  <div class="container-column">
                    <nz-select
                      [(ngModel)]="item.productsSizesID"
                      (ngModelChange)="onChangeProductSize(element, item)"
                      style="width: 100%"
                    >
                      <nz-option
                        *ngFor="let option of element.productSizeOptions"
                        [nzValue]="option.productsSizesID"
                        [nzLabel]="option.size"
                      ></nz-option>
                    </nz-select>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.metalWeight"
                      [disabled]="isSaving"
                      [nzMax]="9999.9999"
                      style="width: 100%"
                      (nzBlur)="onCalculateEstimatedPricing(item)"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.jewelerTime"
                      [disabled]="isSaving"
                      [nzMax]="9999.99"
                      style="width: 100%"
                      (nzBlur)="onCalculateEstimatedPricing(item)"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.setterTime"
                      [disabled]="isSaving"
                      [nzMax]="9999.99"
                      style="width: 100%"
                      (nzBlur)="onCalculateEstimatedPricing(item)"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.chainPrice"
                      [disabled]="isSaving"
                      [nzFormatter]="formatterDollar"
                      [nzParser]="parserDollar"
                      style="width: 100%"
                      (nzBlur)="onCalculateEstimatedPricing(item)"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.stonePrice"
                      [disabled]="isSaving"
                      [nzFormatter]="formatterDollar"
                      [nzParser]="parserDollar"
                      style="width: 100%"
                      (nzBlur)="onCalculateEstimatedPricing(item)"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.findingsPrice"
                      [disabled]="isSaving"
                      [nzFormatter]="formatterDollar"
                      [nzParser]="parserDollar"
                      style="width: 100%"
                      (nzBlur)="onCalculateEstimatedPricing(item)"
                    ></nz-input-number>
                  </div>
                  <div class="container-column" style="text-align: right">
                    {{ item.estimatedPrice ?? 0 | currency }}
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.completeOverridePrice"
                      [disabled]="isSaving"
                      [nzFormatter]="formatterDollar"
                      [nzParser]="parserDollar"
                      style="width: 100%"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <nz-input-number
                      [(ngModel)]="item.laborOverridePrice"
                      [disabled]="isSaving"
                      [nzFormatter]="formatterDollar"
                      [nzParser]="parserDollar"
                      style="width: 100%"
                    ></nz-input-number>
                  </div>
                  <div class="container-column">
                    <i
                      nz-icon
                      nzType="delete"
                      nzTheme="fill"
                      class="icon-delete"
                      (click)="onDeletePricing(item, element)"
                    ></i>
                  </div>
                </div>
                <div
                  class="container-row"
                  *ngIf="element.productSubPricing.length === 0"
                >
                  <div
                    class="container-column"
                    style="text-align: center; grid-column: 1 / 10"
                  >
                    No Pricing for Sizes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr
        class="detail-row"
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
      ></tr>
    </table>
  </div>
</div>
