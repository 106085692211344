import { RivaGems } from 'src/app/components/riva-gems/riva-gems.model';
import { InvoiceProductWeight } from './invoicing-lookup-item';

export interface Invoice {
  invoicesID: number;
  invoicedDate: string;
  custIDNo: number;
  qtyTotal: number;
  poNumber: string[];
  customer: {
    id: number;
    companyName: string;
  };
  invoicesDetails: InvoiceDetail[];
  metalMarket: Partial<MetalMarket>;
  internalComment?: string;
  externalComment?: string;
  invoicePaid?: string;
  shippingCost?: number;
  billingAddress?: BillingAddress | null;
  billingAddressId?: number;
  link: string;
  poComments?: string;
}

export interface BillingAddress {
  address1: string;
  address2: string;
  addressType: boolean;
  city: string;
  country: string;
  custIdno: number;
  customersAddressesId: number;
  phone: string;
  postalCode: string;
  region: string;
}

export interface InvoiceCharge {
  tempUniqueKey?: number;
  invoicesDetailsChargesID: number;
  invoicesID: number;
  invoicesDetailsID: number;
  chargeDescription: string;
  qty: number;
  chargeQty?: number;
  chargePrice?: number;
  price: number;
  priceTotal: number;
}

export enum InvoiceItemType {
  Product,
  ChainRaw,
  ChainFinished,
  Findings,
  OtherComponents,
}

export interface InvoiceDetail {
  key?: number;
  invoicesDetailsID: number;
  orderDetailsAltID?: number;
  ordersDetailsAltID?: number;
  invoicesID: number;
  qty: number;
  maxQty?: number;
  ordersDetailsID: number;
  productName: string;
  material: string;
  size: string;
  cipo: string;
  productPicPath: string;
  pricePerInch?: number;
  price: number;
  priceExtension: number;
  enamelColor: string;
  enamelName: string;
  uom: string;
  customerId?: number;
  productWeights: InvoiceProductWeight[];
  weight?: number;
  materialsCodeId?: number;
  productsSizeId?: number;
  stoneDetail?: string;
  stone: RivaGems;
  stoneType?: string;
  stoneColor?: string;
  stoneSize?: string;
  avgWeightGrams?: number;
  poInternal?: string;
  poExternal?: string;
  poComments?: string;
  detailsComments?: string;
  overrideStoneSize: string;
  overrideStoneSizeID: number;
  chain?: string;
  chainRawLength?: string;
  invoicesDetailsCharges: InvoiceCharge[];
  isCharges?: boolean;
  invoicesDetailsChargesID?: number;
  itemType?: InvoiceItemType;
  isUnfinishedChain?: boolean;
  tempKey?: number;
  rollingWeightAverage?: number;
  unitWeightGrams?: number;
  hasWeight?: boolean;
  productsId?: number;
  materialCodeId?: number;
}

export interface MetalMarket {
  metalMarketID: number;
  marketDate: string | Date;
  enteredDate: string;
  ldpmSilver: number;
  ldpmGold: number;
  ldpmPlatinum: number;
  ldpmPalladium: number;
}
