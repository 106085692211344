import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MaterialCalculation } from 'src/app/models/material-code';
import { MetalMarket, MetalMarketPremiums } from 'src/app/models/metal-market';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { MetalMarketService } from 'src/app/services/metal-market.service';

export const BASE_METAL = {
  gold: 1,
  fairminedGold: 2,
  platinum: 3,
  notApplicable: 4,
  silver: 5,
  palladium: 6,
};

@Component({
  selector: 'app-metal-overhead-equations',
  templateUrl: './metal-overhead-equations.component.html',
  styleUrls: ['./metal-overhead-equations.component.scss'],
})
export class MetalOverheadEquationsComponent implements OnInit {
  constructor(
    private metalMarketService: MetalMarketService,
    private materialCodeService: MaterialCodeService,
  ) {}

  displayedColumns = [
    'description',
    'baseMetal1',
    'preciousPercentage1',
    'baseMetal2',
    'preciousPercentage2',
    'alloyCost',
    'lossFactor',
    'castingFee',
    'bottomCutOff',
    'topCutOff',
    'totalGrams',
    'totalCastingFee',
    'pricePerGram',
    'totalItemCost',
  ];
  metalMarketPremiums: MetalMarketPremiums = {} as MetalMarketPremiums;
  metalMarket: MetalMarket = {} as MetalMarket;
  materialCalculations: MaterialCalculation[] = [];
  baseMetal = BASE_METAL;
  isSaving = false;
  isLoading = false;

  ngOnInit(): void {
    this.getMetalMarketPremiums();
  }

  getMetalMarketPremiums() {
    this.isLoading = true;
    forkJoin([
      this.metalMarketService.getLatestMetalMarket(),
      this.metalMarketService.getMetalMarketPremiums(),
    ]).subscribe(([metalMarket, [premiums]]) => {
      this.metalMarketPremiums = premiums;
      this.metalMarket = metalMarket;
      this.isLoading = false;
      this.getMaterialCodeCalculations();
    });
  }

  getMaterialCodeCalculations() {
    forkJoin([
      this.materialCodeService.getList(),
      this.materialCodeService.getMaterialCodeCalculations(),
    ]).subscribe(
      ([{ responseObject: materialCodes }, materialCodeCalculations]) => {
        this.materialCalculations = materialCodes.reduce((accum, m) => {
          if (m.multiMetal) return accum;
          const calculation = materialCodeCalculations.find(
            (c) => c.materialCodeID === m.materialCodeId,
          );
          return [
            ...accum,
            {
              ...m,
              ...(calculation ?? {}),
            },
          ];
        }, []);
      },
    );
  }

  setMetalMarketPremiums() {
    this.isSaving = true;
    this.metalMarketService
      .setMetalMarketPremiums(this.metalMarketPremiums)
      .subscribe(() => {
        this.isSaving = false;
      });
  }

  getPricePerGram(data: MaterialCalculation) {
    if (!data.baseMetal1 || data.baseMetal1 === BASE_METAL.notApplicable)
      return 0;
    let metalMarketValue1 = 0;
    let premium1 = 0;

    let metalMarketValue2 = 0;
    let premium2 = 0;

    switch (data.baseMetal1) {
      case BASE_METAL.gold:
        metalMarketValue1 = this.metalMarket.ldpmGold;
        premium1 = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue1 = this.metalMarket.ldpmGold;
        premium1 = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue1 = this.metalMarket.ldpmPlatinum;
        premium1 = this.metalMarketPremiums.platinum;
        break;
      case BASE_METAL.silver:
        metalMarketValue1 = this.metalMarket.ldpmSilver;
        premium1 = this.metalMarketPremiums.silver;
        break;
      case BASE_METAL.palladium:
        metalMarketValue1 = this.metalMarket.ldpmPalladium;
        premium1 = this.metalMarketPremiums.palladium;
        break;
    }

    switch (data.baseMetal2) {
      case BASE_METAL.gold:
        metalMarketValue2 = this.metalMarket.ldpmGold;
        premium2 = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue2 = this.metalMarket.ldpmGold;
        premium2 = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue2 = this.metalMarket.ldpmPlatinum;
        premium2 = this.metalMarketPremiums.platinum;
        break;
      case BASE_METAL.silver:
        metalMarketValue2 = this.metalMarket.ldpmSilver;
        premium2 = this.metalMarketPremiums.silver;
        break;
      case BASE_METAL.palladium:
        metalMarketValue2 = this.metalMarket.ldpmPalladium;
        premium2 = this.metalMarketPremiums.palladium;
        break;
    }
    const preciousPercentage1 = data.preciousPercentage1 ?? 0;
    const preciousPercentage2 = data.preciousPercentage2 ?? 0;

    const alloyCost = data.alloyCost ?? 0;
    const lossFactor = data.lossFactor ?? 0;

    const metal1 = (metalMarketValue1 + premium1) * preciousPercentage1;
    const metal2 = (metalMarketValue2 + premium2) * preciousPercentage2;
    return ((metal1 + metal2) / 31.1035 + alloyCost) * lossFactor;
  }

  onSaveCalculations() {
    const request = this.materialCalculations.reduce((accum, calculation) => {
      const hasValue =
        (calculation.baseMetal1 ?? '') !== '' &&
        (calculation.preciousPercentage1 ?? '') !== '' &&
        (calculation.alloyCost ?? '') !== '' &&
        (calculation.lossFactor ?? '') !== '';

      if (hasValue) {
        return [
          ...accum,
          this.materialCodeService.setMaterialCodeCalculation({
            materialCodesCalculationsID:
              calculation.materialCodesCalculationsID ?? 0,
            materialCodeID: calculation.materialCodeId,
            baseMetal1: calculation.baseMetal1,
            preciousPercentage1: calculation.preciousPercentage1,
            baseMetal2: calculation.baseMetal2,
            preciousPercentage2: calculation.preciousPercentage2,
            alloyCost: calculation.alloyCost,
            lossFactor: calculation.lossFactor,
            castingFee: calculation.castingFee,
            bottomCutOff: calculation.bottomCutOff,
            topCutOff: calculation.topCutOff,
          }),
        ];
      } else if (calculation.materialCodesCalculationsID > 0) {
        return [
          ...accum,
          this.materialCodeService.deleteMaterialCodeCalculation(
            calculation.materialCodesCalculationsID,
          ),
        ];
      }
      return accum;
    }, []);
    this.isSaving = true;
    forkJoin(request).subscribe(() => {
      this.isSaving = false;
      this.getMaterialCodeCalculations();
    });
  }

  onComputeTotal(data: MaterialCalculation) {
    const list = Array.from({ length: data.totalGrams }, (_, i) => i + 1);
    data.totalCastingFee = list.reduce(
      (total, value) => total + this.getPriceForCurrentGram(data, value),
      0,
    );
    data.totalItemCost = this.getPricePerGram(data) * data.totalGrams + data.totalCastingFee;
  }

  getPriceForCurrentGram(data: MaterialCalculation, currentGram: number) {
    if (currentGram <= data.bottomCutOff) return data.castingFee;
    if (currentGram > data.topCutOff) return 0;
    return (
      data.castingFee *
      (1 -
        (currentGram - data.bottomCutOff) /
          (data.topCutOff - data.bottomCutOff))
    );
  }
}
