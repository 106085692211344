<h3 mat-dialog-title>Product Pricing</h3>
<mat-dialog-content class="mat-typography">
  <div class="invoicing-detail-dialog">
    <div style="border: 1px solid #646464">
      <table
        mat-table
        [dataSource]="productPricing"
        matSort
        matSortActive="productName"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="productPicPath">
          <th mat-header-cell *matHeaderCellDef>Pic</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 55px;
                padding: 5px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="element.productPicPath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="element.productPicPath"
              />
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="productName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by productName"
          >
            Product Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.productName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="material">
          <th mat-header-cell *matHeaderCellDef>Metal</th>
          <td mat-cell *matCellDef="let element">{{ element.material }}</td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">{{ element.size }}</td>
        </ng-container>
        <ng-container matColumnDef="metalWeight">
          <th mat-header-cell *matHeaderCellDef>Metal Weight</th>
          <td mat-cell *matCellDef="let element">
            {{ element.metalWeight }}
          </td>
        </ng-container>
        <ng-container matColumnDef="jewelerTime">
          <th mat-header-cell *matHeaderCellDef>Jeweler Time</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.jewelerTime"
              [nzMax]="9999.99"
              style="width: 100%"
              (nzBlur)="onCalculatePricing(element)"
            ></nz-input-number>
          </td>
        </ng-container>
        <ng-container matColumnDef="setterTime">
          <th mat-header-cell *matHeaderCellDef>Setter Time</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.setterTime"
              [nzMax]="9999.99"
              style="width: 100%"
              (nzBlur)="onCalculatePricing(element)"
            ></nz-input-number>
          </td>
        </ng-container>
        <ng-container matColumnDef="chainPrice">
          <th mat-header-cell *matHeaderCellDef>Chain Price</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.chainPrice"
              [nzFormatter]="formatterDollar"
              [nzParser]="parserDollar"
              style="width: 100%"
              (nzBlur)="onCalculatePricing(element)"
            ></nz-input-number>
          </td>
        </ng-container>
        <ng-container matColumnDef="stonePrice">
          <th mat-header-cell *matHeaderCellDef>Stone Price</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.stonePrice"
              [nzFormatter]="formatterDollar"
              [nzParser]="parserDollar"
              style="width: 100%"
              (nzBlur)="onCalculatePricing(element)"
            ></nz-input-number>
          </td>
        </ng-container>
        <ng-container matColumnDef="findingsPrice">
          <th mat-header-cell *matHeaderCellDef>Findings Price</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.findingsPrice"
              [nzFormatter]="formatterDollar"
              [nzParser]="parserDollar"
              style="width: 100%"
              (nzBlur)="onCalculatePricing(element)"
            ></nz-input-number>
          </td>
        </ng-container>
        <ng-container matColumnDef="estimatedPrice">
          <th mat-header-cell *matHeaderCellDef>Estimated Price</th>
          <td mat-cell *matCellDef="let element" style="text-align: right">
            {{ element.estimatedPrice ?? 0 | currency }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="11">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 400px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-success"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onReplacePrice()"
      [disabled]="productPricing.length === 0"
    >
      Replace Price
    </button>
  </div>
</mat-dialog-actions>
