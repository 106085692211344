import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { forkJoin, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { STONE_OPEN_ORDER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/stone-open-order-report-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { StoneOpenOrder } from 'src/app/models/orders.model';
import { OrderService } from 'src/app/services/order.service';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { StoneService } from '../riva-gems/stone.service';
import { WorkOrderPrintDialogComponent } from '../work-order-view/work-order-print/work-order-print-dialog.component';
import { WorkOrdersReleaseComponent } from '../work-order-view/work-orders-release.component';
import { StoneItem } from './stone-receiving-dialog/stone-item';
import { StoneReceivingDialogComponent } from './stone-receiving-dialog/stone-receiving-dialog.component';
import { StoneReleaseHistoryDialogComponent } from './stone-release-history-dialog/stone-release-history-dialog.component';

interface Filters {
  CustomerId: number;
  StoneReleased: number;
}
interface StoneOpenOrderModel {
  productsId: number;
  productName: string;
  type: string;
  picturePath: string;
  totalBackOrder: number;
  items: StoneOpenOrder[];
}

@Component({
  selector: 'open-order-stone-report',
  templateUrl: './open-order-stone-report.component.html',
  styleUrls: ['./open-order-stone-report.component.scss'],
  animations: [fadeAnimation],
})
export class OpenOrderStoneReportComponent implements OnInit {
  @ViewChild('errorWorkOrder')
  public readonly errorWorkOrder!: SwalComponent;

  @ViewChild('successRelease')
  public readonly successRelease!: SwalComponent;

  error: { title: string; description: string } = {
    title: '',
    description: '',
  };
  customerList: Array<{ customerId: number; customerName: string }>;
  filter: Filters = {
    CustomerId: 0,
    StoneReleased: 2,
  } as Filters;
  sortBy: string = 'customername';
  sortDirection: string = 'asc';
  openOrders: StoneOpenOrderModel[] = [];
  rawOrders: StoneOpenOrder[] = [];
  orders: StoneOpenOrder[] = [];
  isLoading = true;
  searchQuery = '';
  isScanning = false;
  hasError = false;
  featureKey = STONE_OPEN_ORDER_FEATURE_KEY;

  constructor(
    public dialog: MatDialog,
    private orderService: OrderService,
    private workOrderService: WorkOrderService,
    private stoneService: StoneService,
    private userPermissionService: UserPermissionService
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.stoneOpenOrderReport);
  }

  ngOnInit(): void {
    this.getOpenOrders(true);
  }

  getOpenOrders(isInitial = false) {
    this.orderService
      .getStoneOpenOrder({
        CustomerId: this.filter.CustomerId,
        Status: this.filter.StoneReleased,
        OrderBy: this.sortBy,
        SortDirection: this.sortDirection,
        Take: 0,
      })
      .subscribe(({ data, customers }) => {
        if (isInitial) {
          this.customerList = orderBy(customers, 'companyName');
        }
        this.isLoading = false;
        this.rawOrders = [...data];
        this.onFilter();
      });
  }

  onFilter() {
    const filteredData =
      this.searchQuery !== ''
        ? this.rawOrders.filter((o) =>
            o.workOrdersId.toString().includes(this.searchQuery),
          )
        : [...this.rawOrders];
    this.onParseOpenOrders(filteredData);
  }

  onSortChange() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.getOpenOrders();
  }

  onParseOpenOrders(orders: StoneOpenOrder[]) {
    this.openOrders = orders.reduce<StoneOpenOrderModel[]>((accum, order) => {
      const productIndex = accum.findIndex(
        (i) => i.productsId === order.productsId && i.type === order.type,
      );

      if (productIndex >= 0) {
        const productDetail = accum[productIndex];
        order.link = `/main/orders/${order.ordersId}`;
        order.materialPrintColor1Hex = `#${order.materialPrintColor1}`;
        productDetail.items.push(order);
        productDetail.totalBackOrder =
          productDetail.totalBackOrder + (order.backOrder ?? 0);
        accum[productIndex] = productDetail;
      } else {
        const productDetail = {} as StoneOpenOrderModel;
        order.link = `/main/orders/${order.ordersId}`;
        order.materialPrintColor1Hex = `#${order.materialPrintColor1}`;
        productDetail.productName = order.productName;
        productDetail.picturePath = order.picturePath;
        productDetail.items = [order];
        productDetail.totalBackOrder = order.backOrder ?? 0;
        productDetail.productsId = order.productsId;
        productDetail.type = order.type;
        accum.push(productDetail);
      }
      return accum;
    }, []);

    this.openOrders = this.openOrders.map((o) => ({
      ...o,
      items: Object.values(groupBy(o.items, 'workOrdersId')),
    }));
  }

  onPrintWorkOrder(order: StoneOpenOrder) {
    if (order.workOrdersId <= 0) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: order.printedDate,
          releasedDate: order.releasedDate,
          dateClosed: order.dateClosed,
          workOrdersId: order.workOrdersId,
        },
      },
    });
  }
  onOpenHistoryDialog() {
    this.dialog.open(StoneReleaseHistoryDialogComponent, {
      disableClose: true,
      maxWidth: '1300px',
      width: '100%',
      autoFocus: false,
    });
  }

  onPrint() {
    window.print();
  }

  onScanWorkOrder() {
    const minimumScanningTime$ = of(true).pipe(delay(1500));
    const dialogRef = this.dialog.open(WorkOrdersReleaseComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: {
        workOrders: [],
        action: 'get-work-order-id',
      },
    });
    dialogRef.afterClosed().subscribe((workOrderId) => {
      if (workOrderId) {
        this.isScanning = true;
        this.workOrderService.validateStoneRelease(workOrderId).subscribe(
          ({ status }) => {
            if (status === 1) {
              setTimeout(() => {
                this.isScanning = false;
                this.showErrorOnRelease();
              }, 1000);
            } else {
              forkJoin([
                this.workOrderService.getWorkOrderStones(workOrderId),
                minimumScanningTime$,
              ]).subscribe(
                ([data]) => {
                  this.isScanning = false;
                  if (data.length === 0) {
                    this.showErrorOnRelease();
                  } else {
                    this.openStoneSummary(data, workOrderId);
                  }
                },
                () => {
                  this.showErrorOnRelease();
                },
              );
            }
          },
          () => {
            this.hasError = true;
            setTimeout(() => {
              document.getElementById('error-close-button').focus();
            }, 100);
          },
        );
      }
    });
  }

  onCloseErrorMessage() {
    this.isScanning = false;
    this.hasError = false;
  }

  showErrorOnRelease() {
    this.error.title = 'Stone Released';
    this.error.description =
      'The stones for this work order has already been released.';
    setTimeout(() => {
      this.errorWorkOrder.fire();
    }, 100);
  }

  openStoneSummary(data: StoneItem[], workOrderId) {
    const dialogResponse = this.dialog.open(StoneReceivingDialogComponent, {
      disableClose: true,
      maxWidth: '1200px',
      width: '100%',
      autoFocus: false,
      data: {
        items: data,
        workOrderId,
      },
    });

    dialogResponse.afterClosed().subscribe((updatedData) => {
      if (updatedData != null && Array.isArray(updatedData)) {
        const customerId = this.rawOrders.find(
          (o) => o.workOrdersId === +workOrderId,
        )?.customerId;
        this.workOrderService
          .releaseStoneByWorkOrderId({
            workOrderId,
            details: updatedData.map((u) => ({
              workOrdersDetailsID: u.workOrdersDetailsId,
              stonesId: u.stonesID,
              qty: u.totalStoneQty > 0 ? -u.totalStoneQty : 0,
              customerProvided: u.customerProvided ? customerId : 0,
            })),
          })
          .subscribe(
            () => {
              setTimeout(() => {
                this.successRelease.fire();
              }, 100);
              this.getOpenOrders();
            },
            () => {
              this.showErrorOnRelease();
            },
          );
      }
    });
  }
}
