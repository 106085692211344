<div class="equations-page">
  <div class="equation-value">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Metal Weight</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.metalWeight"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Casting Cost</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.castingCost"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Metal Gram Cost</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.metalGramCost"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Labor Time</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.laborTime"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Labor Time Cost</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.laborTimeCost"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Setting Time</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.settingTime"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Setting Time Cost</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.settingTimeCost"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Stone Cost</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.stoneCost"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Other Cost</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.otherCost"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Overhead Percentage</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.overheadPercentage"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Profit Percentage</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="equation.profitPercentage"
        (ngModelChange)="onValueChange()"
      />
    </mat-form-field>
  </div>
  <div class="total-view">
    <div>
      <div class="total-view-item">
        <div class="label">View of Total Metal Cost</div>
        <div class="value">{{ totalMetalCost | currency }}</div>
      </div>
      <textarea
        nz-input
        [(ngModel)]="totalMetalCostFormula"
        placeholder="Formula"
        nzAutosize
        style="margin-top: 8px"
      ></textarea>
      <button
        class="btn-riva"
        (click)="onValueChange()"
        style="border-radius: 0 0 4px 4px; width: 100%"
      >
        Re-calculate
      </button>
    </div>
    <div>
      <div class="total-view-item">
        <div class="label">View of Other Cost</div>
        <div class="value">{{ totalOtherCost | currency }}</div>
      </div>
      <textarea
        nz-input
        [(ngModel)]="totalOtherCostFormula"
        placeholder="Formula"
        nzAutosize
        style="margin-top: 8px"
      ></textarea>
      <button
        class="btn-riva"
        (click)="onValueChange()"
        style="border-radius: 0 0 4px 4px; width: 100%"
      >
        Re-calculate
      </button>
    </div>
    <div>
      <div class="total-view-item">
        <div class="label">View of Labor Cost</div>
        <div class="value">{{ totalLaborCost | currency }}</div>
      </div>
      <textarea
        nz-input
        [(ngModel)]="totalLaborCostFormula"
        placeholder="Formula"
        nzAutosize
        style="margin-top: 8px"
      ></textarea>
      <button
        class="btn-riva"
        (click)="onValueChange()"
        style="border-radius: 0 0 4px 4px; width: 100%"
      >
        Re-calculate
      </button>
    </div>

    <div>
      <div class="total-view-item">
        <div class="label">View of Overhead Cost</div>
        <div class="value">{{ totalOverheadCost | currency }}</div>
      </div>
      <textarea
        nz-input
        [(ngModel)]="totalOverheadCostFormula"
        placeholder="Formula"
        nzAutosize
        style="margin-top: 8px"
      ></textarea>
      <button
        class="btn-riva"
        (click)="onValueChange()"
        style="border-radius: 0 0 4px 4px; width: 100%"
      >
        Re-calculate
      </button>
    </div>

    <div>
      <div class="total-view-item">
        <div class="label">View of Profit</div>
        <div class="value">{{ totalProfit | currency }}</div>
      </div>
      <textarea
        nz-input
        [(ngModel)]="totalProfitFormula"
        placeholder="Formula"
        nzAutosize
        style="margin-top: 8px"
      ></textarea>
      <button
        class="btn-riva"
        (click)="onValueChange()"
        style="border-radius: 0 0 4px 4px; width: 100%"
      >
        Re-calculate
      </button>
    </div>

    <div>
      <div class="total-view-item">
        <div class="label">Total</div>
        <div class="value">{{ total | currency }}</div>
      </div>
      <textarea
        nz-input
        [(ngModel)]="totalFormula"
        placeholder="Formula"
        nzAutosize
        style="margin-top: 8px"
      ></textarea>
      <button
        class="btn-riva"
        (click)="onValueChange()"
        style="border-radius: 0 0 4px 4px; width: 100%"
      >
        Re-calculate
      </button>
    </div>
  </div>
</div>
