<div id="divMain">
  <div class="work-order__container">
    <div class="work-order__title">
      <div>Work Orders</div>
      <fieldset [disabled]="isLoadingWorkOrder">
        <div style="display: flex">
          <button
            type="button"
            class="btn btn-success"
            style="width: 200px; margin-right: 10px"
            userPermission
            [elementKey]="workOrderFeatureKey.release"
            (click)="onScanWorkOrder('release')"
          >
            Release
          </button>
          <button
            type="button"
            class="btn btn-primary"
            style="width: 200px"
            userPermission
            [elementKey]="workOrderFeatureKey.receive"
            (click)="onScanWorkOrder('close')"
          >
            Receive
          </button>
        </div>
      </fieldset>
    </div>
    <div class="work-order__header">
      <div class="work-order__filters">
        <mat-form-field appearance="fill">
          <mat-label>Status</mat-label>
          <mat-select
            [(value)]="filterByStatus"
            (valueChange)="onFilter('status')"
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="open"> Open </mat-option>
            <mat-option value="closed"> Closed </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Print Status</mat-label>
          <mat-select
            [(value)]="filterByPrintStatus"
            (valueChange)="onFilter('print')"
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="printed"> Printed </mat-option>
            <mat-option value="not-printed"> Not Printed </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Release Status</mat-label>
          <mat-select
            [(value)]="filterByReleaseStatus"
            (valueChange)="onFilter('release')"
          >
            <mat-option value="all">All</mat-option>
            <mat-option value="released"> Released </mat-option>
            <mat-option value="unreleased"> Unreleased </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Customer</mat-label>
          <mat-select
            [(value)]="filterByCustomer"
            (valueChange)="onFilter('customer')"
          >
            <mat-option [value]="0">All</mat-option>
            <mat-option
              *ngFor="let item of customers"
              [value]="item.custIdno"
              >{{ item.companyName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="work-order__search">
        <mat-form-field class="search-input" appearance="fill">
          <mat-label>Search by WKO Number and PO Number (Internal)</mat-label>
          <input
            matInput
            [(ngModel)]="searchOrder"
            (ngModelChange)="onFilter('search')"
          />
        </mat-form-field>
      </div>
    </div>
    <mat-paginator
      [pageSizeOptions]="[8]"
      showFirstLastButtons
      hidePageSize
      style="
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      "
    ></mat-paginator>
    <div [ngClass]="{ hidden: isLoadingWorkOrder }">
      <table
        mat-table
        [dataSource]="workOrders"
        matSort
        matSortActive="printedDate"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="picturePath">
          <th mat-header-cell *matHeaderCellDef>Pic</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 68px;
                padding: 5px;
                height: 68px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="element.picturePath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="element.picturePath"
                onerror="this.src='assets/images/no-image.png'"
              />
              <span
                class="img-custom-work-order work-order-table"
                *ngIf="element.customized"
                >CUSTOM</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="workOrdersId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by workOrdersId"
          >
            WKO #
          </th>
          <td mat-cell *matCellDef="let element">{{ element.workOrdersId }}</td>
        </ng-container>
        <ng-container matColumnDef="orderType">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by orderType"
          >
            Order Type
          </th>
          <td mat-cell *matCellDef="let element">{{ element.orderType }}</td>
        </ng-container>
        <ng-container matColumnDef="productSku">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by productSku"
          >
            Short Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.productSku }}</td>
        </ng-container>
        <ng-container matColumnDef="materialCode">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by materialCode"
          >
            Metal
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngIf="element.materialCode && !element.primaryMaterial"
              style="
                width: 70px;
                height: 40px;
                border: 3px solid black;
                background-color: rgb(255, 255, 255);
                display: flex;
                align-items: center;
                justify-content: center;
                color: black !important;
              "
              [ngStyle]="{
                'background-color': element.printColor1
              }"
            >
              <span>{{ element.materialCode }}</span>
            </div>

            <div class="multi-material" *ngIf="element.primaryMaterial">
              <div
                class="primary-material"
                [ngStyle]="{
                  'border-color':
                    '#' +
                    element.primaryMaterial?.printColor1 +
                    ' transparent transparent transparent'
                }"
              ></div>
              <div
                class="secondary-material"
                [ngStyle]="{
                  'border-color':
                    'transparent transparent #' +
                    element.secondaryMaterial?.printColor1 +
                    ' transparent'
                }"
              ></div>
              <div class="primary-material-code">
                {{ element.primaryMaterial.materialCode }}
              </div>
              <div class="secondary-material-code">
                {{ element.secondaryMaterial.materialCode }}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="qtyTotal">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by qtyTotal"
          >
            Qty
          </th>
          <td mat-cell *matCellDef="let element">{{ element.qtyTotal }}</td>
        </ng-container>
        <ng-container matColumnDef="customerName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by customerName"
          >
            Customer
          </th>
          <td mat-cell *matCellDef="let element">{{ element.customerName }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by createdDate"
          >
            Created Date
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              [title]="element.createdDate | date : 'MM/dd/yyyy hh:mm:ss a'"
              >{{ element.createdDate | date : 'MM/dd/yyyy' }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="poInternal">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by poInternal"
          >
            PO Number
          </th>
          <td mat-cell *matCellDef="let element">
            <a
              *ngIf="element.poInternal || element.poExternal"
              [routerLink]="element.link"
              style="text-decoration: none; font-weight: 700"
            >
              {{ element.poInternal || element.poExternal }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="dueDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by dueDate"
          >
            Due Date
          </th>
          <td mat-cell *matCellDef="let element">
            <span [title]="element.dueDate | date : 'MM/dd/yyyy hh:mm:ss a'">
              {{ element.dueDate | date : 'MM/dd/yyyy' }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="releasedDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by releasedDate"
          >
            Released Date
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              [title]="element.releasedDate | date : 'MM/dd/yyyy hh:mm:ss a'"
            >
              {{ element.releasedDate | date : 'MM/dd/yyyy' }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="printedDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by printedDate"
          >
            Printed Date
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              [title]="element.printedDate | date : 'MM/dd/yyyy hh:mm:ss a'"
            >
              {{ element.printedDate | date : 'MM/dd/yyyy' }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="dateClosed">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by dateClosed"
          >
            Received Date
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.isPartiallyClosed">Partial</span>
            <span
              *ngIf="element.isFullyClosed"
              [title]="element.dateClosed | date : 'MM/dd/yyyy hh:mm:ss a'"
            >
              {{ element.dateClosed | date : 'MM/dd/yyyy' }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="printAction">
          <th mat-header-cell *matHeaderCellDef class="work-order__action"></th>
          <td mat-cell *matCellDef="let element">
            <!-- <button
            class="btn-riva"
            *ngIf="!element.printedDate"
            userPermission
            [elementKey]="workOrderFeatureKey.print"
            (click)="onPrintSelectedWorkOrder(element, printReport)"
          >
            Print
          </button> -->
            <button
              class="btn-riva"
              *ngIf="!element.printedDate"
              userPermission
              [elementKey]="workOrderFeatureKey.print"
              (click)="onPrintWorkOrder(element)"
            >
              Print
            </button>
            <button
              class="btn-view"
              *ngIf="element.printedDate"
              (click)="onPrintWorkOrder(element)"
            >
              View
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="work-order__action"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            'work-order-almost-due': row.isAlmostDue,
            'work-order-already-due': row.isAlreadyDue
          }"
          style="height: 70px"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="14">No Available Data</td>
        </tr>
      </table>
    </div>
    <div style="position: relative">
      <div
        class="loading-orders"
        [ngClass]="{ 'loading-hidden': !isLoadingWorkOrder }"
      >
        <div class="loading-spinner">
          <svg class="spinner" viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
          <h4 style="color: white; margin-top: 120px">
            Loading Work Orders...
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #printReport let-modal>
  <div class="report-popup">
    <div class="modal-header row">
      <div>
        <!-- <button
          *ngIf="!isPrintedReport"
          (click)="onSetPrintedDate()"
          type="button"
          class="btn btn-confirm"
          [useExistingCss]="true"
          printSectionId="print-section"
          ngxPrint
          style="margin: 0"
        >
          <i class="fa fa-print"></i> PRINT REPORT
        </button> -->
        <button
          *ngIf="isPrintedReport"
          (click)="onSetNotPrinted()"
          type="button"
          class="btn btn-confirm"
          style="margin: 0"
          userPermission
          [elementKey]="workOrderFeatureKey.notPrinted"
        >
          Mark as Unprinted
        </button>
      </div>
      <div class="work-order-info">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Due Date</mat-label>
          <input matInput [(ngModel)]="selectedWorkOrder.dueDate" disabled />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Printed Date</mat-label>
          <input
            matInput
            [(ngModel)]="selectedWorkOrder.printedDate"
            disabled
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Released Date</mat-label>
          <input
            matInput
            [(ngModel)]="selectedWorkOrder.releasedDate"
            disabled
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Received Date</mat-label>
          <input
            matInput
            [(ngModel)]="selectedWorkOrder.receivedDate"
            disabled
          />
        </mat-form-field>
      </div>
      <button
        type="button"
        class="btn btn-confirm"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
        style="margin: 0"
      >
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="modal-body">
      <div class="print-preview">
        <div class="print-canvas-container">
          <ng-template [ngTemplateOutlet]="printContent"></ng-template>
        </div>
      </div>

      <div id="print-section" class="print-view" style="display: none">
        <div>
          <ng-template [ngTemplateOutlet]="printContent"></ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #printContent>
  <div class="print-canvas">
    <div class="report-label" style="display: flex">
      <div
        class="material-color"
        [style.background-color]="
          workOrderForPrint.material?.printColor1
            ? '#' + workOrderForPrint.material?.printColor1
            : 'white'
        "
        style="border-right: 3px solid black"
      >
        <ng-container *ngFor="let item of karatItems">
          <h5>{{ workOrderForPrint.material?.code }}</h5>
          <div
            *ngIf="workOrderForPrint.material?.printColor2"
            [style.background-color]="workOrderForPrint.material?.printColor2"
            style="width: 100%; height: 30px"
          ></div>
        </ng-container>
      </div>
      <div class="print-canvas__content">
        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col-12">
                <div class="canvas-field__container" style="margin: 0">
                  <div class="canvas-field__label">Product Name</div>
                  <div class="canvas-field__value">
                    <div
                      class="product-name"
                      [style.font-size]="
                        getFontSize(workOrderForPrint.product?.productName)
                      "
                      [ngStyle]="
                        workOrderForPrint.customized && {
                          color: 'red'
                        }
                      "
                    >
                      {{ workOrderForPrint.product?.productName ?? '' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8 canvas-flex-wrap">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">Description</div>
                  <div class="canvas-field__value description">
                    {{ workOrderForPrint.product?.description ?? '' }}
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">SKU</div>
                  <div class="canvas-field__value">
                    {{ workOrderForPrint.product?.sku ?? '' }}
                  </div>
                </div>
                <div class="canvas-field__container">
                  <div class="canvas-field__label">QTY</div>
                  <div class="canvas-field__value">
                    {{ workOrderForPrint.quantity ?? 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">PO External / Internal</div>
                  <div class="canvas-field__value">
                    {{ workOrderForPrint.purchaseOrder?.poExternal ?? '-' }}
                    /
                    {{ workOrderForPrint.purchaseOrder?.poInternal ?? '-' }}
                  </div>
                </div>
              </div>
              <div class="col-4 canvas-flex-wrap">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">Due Date</div>
                  <div class="canvas-field__value">
                    {{ workOrderDueDate | date : 'MM/dd/yyyy' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">Created Date</div>
                  <div class="canvas-field__value">
                    {{
                      workOrderForPrint.workOrder?.createdDate
                        | date : 'MM/dd/yyyy'
                    }}
                  </div>
                </div>
              </div>
              <div class="col canvas-flex-wrap">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">Release Date</div>
                  <div class="canvas-field__value"></div>
                </div>
              </div>
              <div class="col canvas-flex-wrap">
                <div class="canvas-field__container">
                  <div class="canvas-field__label">Released By</div>
                  <div class="canvas-field__value"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 text-center">
            <div class="col-12 product-image__container">
              <div class="product-image">
                <img
                  src="{{
                    workOrderForPrint.product?.picture ||
                      'assets/images/no-image.png'
                  }}"
                />
              </div>
              <span
                class="img-custom-work-order"
                *ngIf="workOrderForPrint.customized"
                >CUSTOM</span
              >
            </div>
            <div class="col-12" style="margin-top: 10px">
              <!-- <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="
                  return(workOrderForPrint.workOrder?.workOrdersId | idPipe)
                "
                cssClass="bshadow"
                [width]="130"
                [margin]="1"
              ></ngx-qrcode> -->
            </div>
            <div class="col-12">
              <h4>
                {{ workOrderForPrint.workOrder?.workOrdersId | idPipe }}
              </h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="canvas-field__container" style="height: 350px">
              <div class="canvas-field__label">Sub SKU & Quantities</div>
              <div class="canvas-field__value">
                <table
                  class="table sub-item-table"
                  *ngIf="workOrderForPrint.workOrderDetails?.length"
                >
                  <thead>
                    <tr>
                      <th style="width: 120px">CI-PO</th>
                      <th style="width: 60px">QTY</th>
                      <th style="width: 100px">Size</th>
                      <th style="width: 250px">Enamel Color</th>
                      <th *ngIf="!workOrderForPrint.customized">Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let workOrder of workOrderForPrint.workOrderDetails
                      "
                    >
                      <td>{{ workOrder.orderDetails?.cipo }}</td>
                      <td>{{ workOrder.orderDetails?.qtyOrdered }}</td>
                      <td>{{ workOrder.orderDetails?.size }}</td>
                      <td>
                        <div style="display: flex; align-items: center">
                          <div
                            *ngIf="workOrder.orderDetails?.enamel?.colorHex"
                            style="
                              width: 60px;
                              height: 30px;
                              border: 3px solid black;
                              background-color: rgb(255, 255, 255);
                              margin-right: 10px;
                            "
                            [ngStyle]="{
                              'background-color':
                                workOrder.orderDetails?.enamel?.colorHex
                            }"
                          ></div>
                          <div>
                            {{ workOrder.orderDetails?.enamel?.enamelName }}
                          </div>
                        </div>
                      </td>
                      <td
                        *ngIf="!workOrderForPrint.customized"
                        style="
                          max-width: 0;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ workOrder.orderDetails?.comment }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="canvas-field__container"
                  style="margin: 0; min-height: 75px"
                  *ngIf="workOrderForPrint.customized"
                >
                  <div class="canvas-field__label" style="font-size: 16px">
                    Comment
                  </div>
                  <div class="canvas-field__value">
                    {{
                      workOrderForPrint.workOrderDetails[0]?.orderDetails
                        ?.comment
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="canvas-field__container" style="height: 470px">
              <div class="canvas-field__label">Bill of Materials</div>
              <div class="canvas-field__value">
                <table class="table sub-item-table" *ngIf="subItems.length">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>UOM</th>
                      <th>Size & MFG Method</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let subItem of subItems">
                      <td style="width: 70px">
                        <div
                          style="
                            width: 70px;
                            display: flex;
                            justify-content: center;
                          "
                          *ngIf="subItem.picPath"
                        >
                          <img
                            style="max-height: 37px; max-width: 60px"
                            [src]="subItem.picPath"
                          />
                        </div>
                      </td>
                      <td style="width: 30%">{{ subItem.name }}</td>
                      <td>{{ subItem.description }}</td>
                      <td>{{ subItem.unitOfMeasure?.description }}</td>
                      <td>
                        <div
                          [ngClass]="{
                            'two-column': subItem.subItemsInfo?.length > 4
                          }"
                        >
                          <div
                            class="sub-items__info"
                            *ngFor="let subItemInfo of subItem.subItemsInfo"
                          >
                            <div class="sub-items__info-size">
                              <span>{{ subItemInfo.size }}</span>
                            </div>
                            <div class="sub-items__info-details">
                              <div>{{ subItemInfo.mfgMethodText }}</div>
                              <span
                                class="other-details"
                                *ngIf="subItemInfo.mfgMethodDetails"
                                >{{ subItemInfo.mfgMethodDetails }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style="margin: 10px 0; font-weight: bold"
                  *ngIf="productFindings.length"
                >
                  Findings
                </div>
                <table
                  class="table sub-item-table"
                  *ngIf="productFindings.length"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Qty</th>
                      <th>SKU</th>
                      <th>Material</th>
                      <th>Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let productFinding of productFindings">
                      <td style="width: 70px">
                        <div
                          style="
                            width: 70px;
                            display: flex;
                            justify-content: center;
                          "
                          *ngIf="productFinding.findings.picPath"
                        >
                          <img
                            style="max-height: 37px; max-width: 60px"
                            [src]="productFinding.findings.picPath"
                          />
                        </div>
                      </td>
                      <td style="width: 80px">
                        {{ productFinding.qty }}
                      </td>
                      <td>{{ productFinding.findings.sku }}</td>
                      <td>{{ productFinding.material.description }}</td>
                      <td>{{ productFinding.size }}</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  *ngIf="
                    productStonesVariations.length ||
                    productStonesConstants.length
                  "
                >
                  <div style="margin: 10px 0; font-weight: bold">Stones</div>
                  <table class="table sub-item-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Qty</th>
                        <th>Type</th>
                        <th>Cut</th>
                        <th>Clarity</th>
                        <th>Color</th>
                        <th>Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let stoneVariation of productStonesVariations"
                        style="color: blue"
                      >
                        <td style="width: 70px">
                          <div
                            style="
                              width: 70px;
                              display: flex;
                              justify-content: center;
                            "
                            *ngIf="stoneVariation.picturePath"
                          >
                            <img
                              style="max-height: 37px; max-width: 60px"
                              [src]="stoneVariation.picturePath"
                            />
                          </div>
                        </td>
                        <td style="width: 80px">
                          {{ stoneVariation.qty }}
                        </td>
                        <td>
                          {{ stoneVariation.type }}
                        </td>
                        <td>{{ stoneVariation.cut }}</td>
                        <td>{{ stoneVariation.clarity }}</td>
                        <td>{{ stoneVariation.color }}</td>
                        <td>{{ stoneVariation.size }}</td>
                      </tr>
                      <tr *ngFor="let stoneConstant of productStonesConstants">
                        <td style="width: 70px">
                          <div
                            style="
                              width: 70px;
                              display: flex;
                              justify-content: center;
                            "
                            *ngIf="stoneConstant.stone?.picturePath"
                          >
                            <img
                              style="max-height: 37px; max-width: 60px"
                              [src]="stoneConstant.stone.picturePath"
                            />
                          </div>
                        </td>
                        <td style="width: 80px">
                          {{ stoneConstant.qty }}
                        </td>
                        <td>
                          {{ stoneConstant.stone.stoneType.name }}
                        </td>
                        <td>{{ stoneConstant.stone.stoneCut.name }}</td>
                        <td>{{ stoneConstant.stone.stoneClarity.name }}</td>
                        <td>{{ stoneConstant.stone.stoneColor.colorName }}</td>
                        <td>{{ stoneConstant.stone.stoneSize.displayText }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  style="display: grid; grid-template-columns: 80px 1fr"
                  *ngIf="enamels.length"
                >
                  <div style="font-weight: bold; margin-top: 14px">Enamel</div>
                  <div
                    style="display: flex; flex-wrap: wrap; align-items: center"
                  >
                    <div
                      *ngFor="let enamel of enamels"
                      style="
                        display: flex;
                        align-items: center;
                        margin: 5px 10px;
                      "
                    >
                      <div
                        *ngIf="enamel?.colorHex"
                        style="
                          width: 40px;
                          height: 40px;
                          border: 3px solid black;
                          background-color: rgb(255, 255, 255);
                          margin-right: 5px;
                        "
                        [ngStyle]="{
                          'background-color': enamel?.colorHex
                        }"
                      ></div>
                      <div>{{ enamel.enamelName }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="canvas-footer">
          <div class="mission">
            Our Mission is to honor God through our Service, Process, and
            Product Excellence.
          </div>
          <div class="canvas-footer__logo">
            <img src="../../../assets/images/riva-logo-single.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="print-canvas page-2">
    <div class="row">
      <div class="col">
        <div class="canvas-field__container" style="height: 1350px">
          <div class="canvas-field__label">Product Routing</div>
          <div class="canvas-field__value">
            <table class="table sub-item-table" *ngIf="productRoutings?.length">
              <thead>
                <tr>
                  <th>Activity Code</th>
                  <th>Department</th>
                  <th>Description</th>
                  <th>Standard Time</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let routing of productRoutings">
                  <td>{{ routing.routingCode?.activityCode }}</td>
                  <td>{{ routing.routingCode?.department }}</td>
                  <td>{{ routing.routingCode?.activityDesc }}</td>
                  <td>
                    {{ routing.standardTime }}
                  </td>
                  <td>
                    {{ routing.comment }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #createWorkOrder let-modal>
  <div class="report-popup">
    <div class="modal-header row">
      <h5 class="modal-title col-10" id="modal-basic-title">
        Create Work Order
      </h5>
    </div>
    <div class="modal-body">
      <div class="create-form">
        <div class="row wo-form">
          <div class="col-8 wo" style="padding: 0; padding-top: 15px">
            <div class="row">
              <div class="col">
                <!-- <select [(ngModel)]="selectedOrderId" (change)="selectedOrderItem = 0;" class="custom-select form-control">
                                    <option [value]="0" disabled>-- Orders --</option>
                                    <option *ngFor="let item of orders" [value]="item.ordersId">{{item.pointernal}}</option>
                                </select> -->
                <!-- <mat-form-field appearance="fill" style="width: 100%">
                                    <mat-label>Orders</mat-label>
                                    <mat-select [(ngModel)]="selectedOrderId">
                                      <mat-option>
                                        <ngx-mat-select-search
                                          placeholderLabel="Find order..."
                                          noEntriesFoundLabel="No matching order found"
                                          [(ngModel)]="searchOrder"
                                          (ngModelChange)="filterOrders($event)"
                                        ></ngx-mat-select-search>
                                      </mat-option>
                                      <mat-option
                                        *ngFor="let item of filteredOrders"
                                        [value]="item.ordersId"
                                      >
                                        <div class="order-select-item">
                                          <div class="order-select-item__title">
                                            {{ item.pointernal }}
                                          </div>
                                          <div class="order-select-item__sub-title">
                                            <span>{{item.customer.customerId}}</span>&nbsp;
                                            <span>{{ item.entryDate | date: 'yyyy-MM-dd' }}</span>
                                          </div>
                                        </div>
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field> -->
                <mat-form-field
                  class="orders-select-container"
                  appearance="fill"
                  style="width: 100%"
                >
                  <mat-label>Orders</mat-label>
                  <mat-select #orderSelect>
                    <mat-select-trigger>{{
                      orderSelect.value
                    }}</mat-select-trigger>
                    <mat-option class="orders-select-search">
                      <!-- <lib-mat-select-search
                        [list]="orders"
                        [searchProperties]="['pointernal']"
                        (filtered)="filteredOrders = $event"
                      >
                      </lib-mat-select-search> -->
                    </mat-option>
                    <mat-option
                      class="orders-select-header"
                      *ngIf="filteredOrders.length"
                    >
                      <div class="orders-select">
                        <div class="p5">PO Internal</div>
                        <div class="p5">Customer Code</div>
                        <div class="p5">Entry Date</div>
                      </div>
                    </mat-option>
                    <mat-option
                      *ngFor="let order of filteredOrders"
                      [value]="order.pointernal"
                      style="
                        padding: 0;
                        border-bottom: 1px solid rgb(48, 48, 48);
                      "
                      (click)="onSelectOrder(order)"
                    >
                      <div class="country-container orders-select">
                        <div class="p5">{{ order.pointernal }}</div>
                        <div class="p5">
                          {{ order.customer?.customerId ?? '' }}
                        </div>
                        <div class="p5">
                          {{ order.entryDate | date : 'MM/dd/yyyy' }}
                        </div>
                      </div>
                    </mat-option>
                    <mat-option *ngIf="!filteredOrders.length">
                      <div class="country-container text-center">Not Found</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col">
                <select
                  [(ngModel)]="selectedOrderItem"
                  (change)="showOrderDetail()"
                  class="custom-select form-control"
                  [disabled]="selectedOrderId == 0"
                >
                  <option [value]="0" disabled>-- Order Items --</option>
                  <option
                    *ngFor="let item of orderItems"
                    [value]="item.ordersDetailsId"
                  >
                    {{ item.products.productName }}
                  </option>
                </select>
              </div>
              <div class="col"></div>
            </div>
            <br />
            <div *ngIf="selectedOrderItem != 0" class="row">
              <div class="col">
                <img
                  src="{{ selectedOrderDetail.products.picPath }}"
                  style="width: 250px; height: auto; border: solid 1px white"
                />
              </div>
              <div class="col">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>QUANTITY</mat-label>
                  <input
                    matInput
                    type="number"
                    min="1"
                    [(ngModel)]="workOrderDetailDisplay.workOrderDetail.qtyinit"
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>ORDER QUANTITY</mat-label>
                  <input
                    matInput
                    [(ngModel)]="selectedOrderDetail.qtyordered"
                    disabled
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>METAL TYPE: </mat-label>
                  <input
                    matInput
                    [value]="workOrderDetailDisplay.material"
                    disabled
                  />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>SIZE: </mat-label>
                  <input
                    matInput
                    [value]="workOrderDetailDisplay.size"
                    disabled
                  />
                </mat-form-field>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-confirm button-container"
                  (click)="addToWorkOrder()"
                >
                  Add to Work Order &nbsp;<i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col wo-details" style="padding: 0">
            <div class="wo-details-item-container">
              <div
                *ngFor="let item of detailDisplays"
                class="row wo-detail-item border-bottom"
              >
                <div class="col-2">
                  <img
                    src="{{ item.photo }}"
                    style="width: 50px; height: auto"
                  />
                </div>
                <div class="col" style="color: white">
                  <div class="row">{{ item.productName }}</div>
                  <div class="row">{{ item.material }}</div>
                  <div class="row">Size {{ item.size }}</div>
                </div>
                <div class="col-1" style="color: white">
                  x&nbsp;{{ item.workOrderDetail.qtyinit }}
                </div>
                <div class="col-1" style="color: white">
                  <i
                    (click)="removeDetail(item, item.workOrderDetail)"
                    class="fa fa-trash"
                  ></i>
                </div>
              </div>
            </div>

            <div class="row button-container">
              <button
                (click)="saveWorkOrder()"
                type="button"
                class="btn btn-confirm"
                [disabled]="detailDisplays.length == 0"
              >
                <i class="fa fa-plus"></i> Create Work Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer row">
      <button
        class="btn btn-confirm"
        (click)="modal.dismiss('Cross click'); revertWorkOrder()"
      >
        CANCEL
      </button>
    </div>
  </div>
</ng-template>

<ng-template #closeWorkOrders let-modal>
  <div class="report-popup">
    <div class="modal-header row">
      <h5 class="modal-title col-10" id="modal-basic-title">
        Close Work Orders
      </h5>
    </div>
    <div class="modal-body">
      <div class="create-form">
        <div class="row wo-form">
          <div class="col wo" style="padding: 0">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>SEARCH WKO#</mat-label>
              <input
                matInput
                [(ngModel)]="searchText"
                (keyup)="filterOpenWKOs()"
                value="{Number(searchText).toString()}"
              />
            </mat-form-field>
            <!-- <input type="text" class="form-control search-text search-field" 
                        placeholder="SEARCH WKO#" [(ngModel)]="searchString" (keyup)="filterOpenWKOs();"> -->
            <table class="table wko-table">
              <tr>
                <th>WKO</th>
                <th>Created</th>
                <th>Location</th>
              </tr>
              <tr
                class="row-data"
                *ngFor="let item of filtered_openWKOs"
                (click)="selectOpenWKO(item)"
              >
                <td>{{ item.workOrdersId | idPipe }}</td>
                <td>{{ item.createdDate | date : 'MM/dd/yyyy' }}</td>
                <td>{{ item.location }}</td>
              </tr>
            </table>
          </div>
          <div class="col-8 wo-details" style="padding: 0">
            <div class="wo-details-item-container">
              <table class="table" *ngIf="openWKO_details.length > 0">
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>M</th>
                  <th>S</th>
                  <th>Init</th>
                  <th>Closed</th>
                  <th></th>
                </tr>
                <tr *ngFor="let item of openWKO_details">
                  <td>
                    <img
                      src="{{ item.itemPhoto }}"
                      style="width: 50px; height: auto"
                    />
                  </td>
                  <td>{{ item.itemName }}</td>
                  <td>{{ item.metal }}</td>
                  <td>{{ item.size }}</td>
                  <td>{{ item.qtyinit }}</td>
                  <td>{{ item.qtyclosed }}</td>
                  <td>
                    <i
                      *ngIf="!(item.qtyinit == item.qtyclosed)"
                      class="fa fa-times-circle"
                      (click)="closeWkoDetail(item)"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row button-container">
            <button
              (click)="closeWko(openWKO.workOrdersId)"
              type="button"
              class="btn btn-confirm"
              [disabled]="openWKO.workOrdersId == 0"
            >
              Close Work Order
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer row">
      <button
        class="btn btn-confirm"
        (click)="modal.dismiss('Cross click'); resetOpenWKO()"
      >
        CLOSE
      </button>
    </div>
  </div>
</ng-template>

<swal
  #confirmationWorkOrder
  [title]="confirmation.title"
  [text]="confirmation.description"
  [icon]="confirmation.icon"
  confirmButtonText="Done"
  [showConfirmButton]="confirmation.icon === 'error'"
  [timer]="confirmation.timer"
>
</swal>
